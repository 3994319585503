import { commonAxios } from "../utils/AxiosSettings";
import dayjs from "dayjs";

export async function getAllHolidays(selectedMonthYear, mode, FactorySelected) {
    if (selectedMonthYear && mode) {
        const res = await commonAxios.get(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/holiday/?${mode}=${selectedMonthYear}&Factory=${FactorySelected}  
        `
        );
        return res;
    } else {
        const res = await commonAxios.get(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/holiday/?Factory=${FactorySelected}  
        `
        );

        return res;
    }
}
export async function addHoliday(eventName, duration, factory) {
    const endDate = `${dayjs(duration[1])
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss.SSSSSS+00:00")}`;
    const res = commonAxios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/holiday/
        `,

        {
            eventName: eventName,
            startDate: duration[0],
            endDate: endDate,
            Factory: factory,
        }
    );

    return res;
}
export async function updateHoliday(id, eventName, duration, factory) {
    const res = commonAxios.patch(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/holiday/?id=${id}
        `,
        {
            eventName: eventName,
            startDate: `${dayjs(duration[0]).format(
                "YYYY-MM-DD"
            )} 00:00:00.000000+00:00`,

            endDate: `${dayjs(duration[1])
                .endOf("day")
                .format("YYYY-MM-DD HH:mm:ss.SSSSSS+00:00")}`,
            Factory: factory,
        }
    );
    return res;
}
export async function deleteHoliday(id) {
    const res = commonAxios.delete(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/holiday/?id=${id}
        `
    );

    return res;
}
