// Main component of QC Report, will have tabs, progress bar and buttons
import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { caseReportDataState } from "../../../RecoilState/caseReportDataState";
import { Tabs, Layout, Spin, Divider } from "antd";
import CaseClosing from "../../CaseReport/GENERALcomponent/CaseClosing";
import CaseDetailsGeneral from "../../CaseReport/GENERALcomponent/CaseDetailsGeneral";
import CaseReportDetails from "../../CaseReport/GENERALcomponent/CaseReportDetails";
import ReplyFromWorker from "../../CaseReport/ReplyFromWorker";
import FeedbackReport from "../../CaseReport/FeedbackReport";
import { Alert, message } from "antd";
import { useLocation, useNavigate, useParams } from "react-router";
import { getCaseDetailsByID } from "../../../Adapters/CaseCRUDCalls";
import LoggedInComponent from "../../../containers/LoggedInComponent/LoggedInComponent";
import {
    getQCcaseReport,
    submitDraftQCReport,
    updateDraftQCStatus,
} from "../../../Adapters/QCcalls";
import { QCCaseReportDataState } from "../../../RecoilState/QCCaseReportDataState";
import styles from "../QualityChecker.module.css"; // Make sure the styles are defined here
import { userState } from "../../../RecoilState/userState";
import { previousRouteData } from "../../../RecoilState/previousRouteData";
import { Link } from "react-router-dom";
import { getQCReportTabStatus } from "../../../hooks/getQCReportTabStatus";
import isEmpty from "lodash/isEmpty";
import ViewLog from "../../ViewLog";
import { getCounterFromCaseStatus } from "../../../hooks/getCounterFromCaseStatus";
import "../../../styles/antd-override.scss";
import ConfirmationModal from "../../../components/QualityChecker/Modals/ConfirmationModal";
import GeneralInstructionModal from "../../../components/QualityChecker/Modals/GeneralInstructionModal";
import { QuestionCircleOutlined } from "@ant-design/icons";
import QCForm from "../../CaseReport/FeedbackReport/QCForm";
import { getQCReportTab } from "../../../hooks/getQCReportTab.js";
import RenderQCFooter from "../../../components/QualityChecker/Footer/renderQCFooter.jsx";
const { TabPane } = Tabs;
const { Footer } = Layout;

const QCCaseReport = () => {
    const [caseDataState, setCaseDataState] =
        useRecoilState(caseReportDataState);
    const [audioHeard, setAudioHeard] = useState(false);
    const [currentTab, setCurrentTab] = useState("1");
    const [user, setUser] = useRecoilState(userState);
    const [previousRoute, setPreviousRoute] = useRecoilState(previousRouteData);
    const [QCcaseDatastate, setQCcaseDatastate] = useRecoilState(
        QCCaseReportDataState
    );
    const [openCompleteAction, setOpenCompleteAction] = useState(false);
    const [tabProgess, setTabProgress] = useState(0);
    const [tab, setTab] = useState({});
    const params = useParams();
    let id = params?.caseID;
    const [RAFieldflagValues, setRAFieldflagValues] = useState({
        closeModalFlag: false, //to show close modal for particular template
        closingRemarkMandatory: false, //to set all the fields of "closing remark" for special cases as mandatory and non mandator for RA
        CBRRMandatory: false, //to set "remark"- Comment by RA filed for Posh cases as mandatory and non mandator
    });
    const [status, setStatus] = useState();
    const [translatedDataObj, setTranslatedDataObj] = useState({});
    const [showAudioError, setShowAudioError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openConfirmAction, setOpenConfirmAction] = useState(false);
    const [instructionModalOpen, setInstructionModalOpen] = useState(false);
    const [visitedTabs, setVisitedTabs] = useState(1);
    const [customInkBarWidth, setCustomInkBarWidth] = useState(100 / 6);
    const { state } = useLocation();
    const navigate = useNavigate();

    const navToPage = (url) => {
        navigate(url, { state: { pageNumber: state.pageNumber } });
    };

    const handleTabChange = (key) => {
        setCurrentTab(key);
    };

    const getTabClassName = (tabKey) => {
        const visited = visitedTabs >= tabKey;
        const active = tabKey === currentTab;
        return visited ? (active ? "visited active" : "visited") : "";
    };
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const caseDetailsResponse = await getCaseDetailsByID(id);
            setCaseDataState(caseDetailsResponse.data);

            const qcCaseReportResponse = await getQCcaseReport(id);
            setQCcaseDatastate(qcCaseReportResponse.data);
            if (qcCaseReportResponse?.data?.qcReport?.reportType) {
                setStatus(qcCaseReportResponse?.data?.qcReport?.reportType);
            }

            const ccReportTab = qcCaseReportResponse?.data?.ccReport
                ? true
                : false;
            const tabs = getQCReportTab(
                !isEmpty(qcCaseReportResponse?.data?.reply),
                !isEmpty(qcCaseReportResponse?.data?.previous_qcreport),
                ccReportTab
            );

            setTab(tabs);
            if (user?.id && id) {
                if (
                    getCounterFromCaseStatus(
                        caseDetailsResponse?.data?.CaseStatus
                    ) == 8
                ) {
                    submitDraftQCReport({
                        user_id: user?.id,
                        case_id: id,
                        progress: isEmpty(
                            qcCaseReportResponse?.data?.previous_qcreport
                        )
                            ? "General"
                            : "Qc Prev Report",
                        audio_flag: false,
                    }).then((res) => {
                        if (res) {
                            if (res?.message) {
                                getCounterFromCaseStatus(
                                    caseDetailsResponse.data?.CaseStatus
                                ) == 8 && setInstructionModalOpen(true);
                            }

                            if (res?.status) setStatus(res.status);
                            if (res?.audio_flag) setAudioHeard(res.audio_flag);
                            if (res?.progress) {
                                const tabPresent = getQCReportTabStatus(
                                    tabs,
                                    res?.progress
                                );
                                setTabProgress(tabPresent);
                                setVisitedTabs(tabPresent);
                                setCurrentTab(tabPresent.toString());
                                var size = Object.keys(tabs).length;
                                const newWidth =
                                    Math.max(tabPresent, 1) * (100 / size);
                                setCustomInkBarWidth(newWidth);
                            }
                        }
                    });
                } else {
                    setAudioHeard(true);
                    var size = Object.keys(tabs).length;
                    setTabProgress(size);
                    setVisitedTabs(size);
                    setCurrentTab(size.toString());
                    const newWidth = Math.max(size, 1) * (100 / size);
                    setCustomInkBarWidth(newWidth);
                }
            }
            setLoading(false);
        };
        if (user?.id) fetchData();
    }, [user?.id]);

    const updateStatus = (details, tabs) => {
        if (tabProgess <= parseInt(tabs)) {
            updateDraftQCStatus({
                ...details,
                user_id: user.id,
                case_id: id,
            }).then((res) => {
                const tabPresent = parseInt(tabs);
                setTabProgress(tabs);
                setVisitedTabs(tabs);
                var size = Object.keys(tab).length;
                const newWidth = Math.max(tabs, 1) * (100 / size);
                setCustomInkBarWidth(newWidth);
            });
        }
        handleTabChange(tabs.toString());
    };

    // Calculate which footer buttons to show based on the currentTab

    const renderTabComponent = (tabName) => {
        switch (tabName) {
            case "Qc Prev Report":
                return (
                    <QCForm
                        readOnly={true}
                        status={QCcaseDatastate?.previous_qcreport?.reportType}
                        QCcaseData={QCcaseDatastate?.previous_qcreport}
                    />
                );
            case "General":
                return (
                    <>
                        <CaseDetailsGeneral
                            caseData={caseDataState}
                            setAudioHeard={setAudioHeard}
                        />
                        <Divider className={styles.caseRepDivider} />
                    </>
                );
            case "Details":
                return (
                    <>
                        <CaseReportDetails
                            caseDatastate={caseDataState}
                            setcaseDatastate={setCaseDataState}
                        />
                        <Divider className={styles.caseRepDivider} />
                    </>
                );
            case "Closing Report":
                return (
                    <CaseClosing
                        caseData={caseDataState}
                        setRAFieldflagValues={setRAFieldflagValues}
                        translatedDataObj={translatedDataObj}
                        setTranslatedDataObj={setTranslatedDataObj}
                        disableResolvingReport={false}
                        isCTResolveCase={false}
                    />
                );
            case "Reply":
                return (
                    <>
                        <ReplyFromWorker caseData={QCcaseDatastate?.reply} />
                        <Divider className={styles.caseRepDivider} />
                    </>
                );
            case "View Logs":
                return (
                    <>
                        <ViewLog view={true} caseData={caseDataState} />
                        <Divider className={styles.caseRepDivider} />
                    </>
                );
            case "Qc Report":
                return (
                    <>
                        <FeedbackReport
                            caseData={QCcaseDatastate}
                            status={status}
                            setStatus={setStatus}
                            openCompleteAction={openCompleteAction}
                            setOpenCompleteAction={setOpenCompleteAction}
                            setOpenConfirmAction={setOpenConfirmAction}
                        />
                    </>
                );
        }
    };
    return (
        <Layout>
            <LoggedInComponent>
                {showAudioError &&
                    message.error({
                        key: "AudioError",
                        content: (
                            <Alert
                                message="Audio Not Played"
                                description="Please make sure to listen to the audio, only then you will be able to proceed any further."
                                type="error"
                                showIcon
                                closable
                            />
                        ),
                        icon: <></>,
                        onClick: () => {
                            message.destroy("AudioError");
                            setShowAudioError(false);
                        },
                        onClose: () => {
                            setShowAudioError(false);
                        },
                    })}
                <GeneralInstructionModal
                    open={instructionModalOpen}
                    setOpen={setInstructionModalOpen}
                />
                {openConfirmAction && (
                    <ConfirmationModal
                        open={openConfirmAction}
                        setOpen={setOpenConfirmAction}
                        setStatus={setStatus}
                        handleTab={updateStatus}
                        tab={tab}
                    />
                )}
                <div className={styles.wholeContainer}>
                    <div className={styles.header}>
                        <h4>
                            <span
                                onClick={() =>
                                    navToPage(
                                        user.role === "SUPER_ADMIN" ||
                                            user.role === "FACTORY_ADMIN" ||
                                            user.role === "REGIONAL_ADMIN"
                                            ? previousRoute.defaultRoute
                                            : previousRoute.defaultRoute
                                    )
                                }>
                                <img
                                    src="/assets/images/back/Group 4495.svg"
                                    alt="back-logo"
                                />
                            </span>
                            <span style={{ paddingLeft: "20px" }}>
                                Case Report
                            </span>
                        </h4>
                    </div>
                    <div id="report" className={styles.mainContainer}>
                        <div className={styles.tabContent}>
                            {loading && isEmpty(tab) ? (
                                <div style={{ textAlign: "center" }}>
                                    <Spin />
                                </div>
                            ) : (
                                <>
                                    <Tabs
                                        style={{
                                            "--custom-ink-bar-width": `${
                                                2 * customInkBarWidth
                                            }%`,
                                        }}
                                        activeKey={currentTab}
                                        onChange={handleTabChange}
                                        className={"custom-tabs"}>
                                        {Object.keys(tab).map(
                                            ([key, value]) => {
                                                return (
                                                    <TabPane
                                                        tab={
                                                            tab[key] ==
                                                            "Qc Prev Report"
                                                                ? `Qc ${
                                                                      QCcaseDatastate
                                                                          ?.previous_qcreport
                                                                          ?.qcreport_version <
                                                                      10
                                                                          ? `0${QCcaseDatastate?.previous_qcreport?.qcreport_version}`
                                                                          : QCcaseDatastate
                                                                                ?.previous_qcreport
                                                                                ?.qcreport_version
                                                                  } Report`
                                                                : tab[key]
                                                        }
                                                        key={key.toString()}
                                                        className={getTabClassName(
                                                            key
                                                        )}
                                                        disabled={
                                                            visitedTabs < key
                                                        }>
                                                        {renderTabComponent(
                                                            tab[key]
                                                        )}
                                                    </TabPane>
                                                );
                                            }
                                        )}
                                    </Tabs>

                                    <Footer className={styles.footer}>
                                        <div>
                                            {currentTab != 6 &&
                                                getCounterFromCaseStatus(
                                                    caseDataState?.CaseStatus
                                                ) &&
                                                getCounterFromCaseStatus(
                                                    caseDataState?.CaseStatus
                                                ) == 8 && (
                                                    <label>
                                                        <QuestionCircleOutlined
                                                            className={
                                                                styles.footerhelp
                                                            }
                                                            onClick={
                                                                setInstructionModalOpen
                                                            }
                                                        />
                                                        SOP
                                                    </label>
                                                )}
                                        </div>
                                        <RenderQCFooter
                                            tab={tab}
                                            audioHeard={audioHeard}
                                            currentTab={currentTab}
                                            visitedTabs={visitedTabs}
                                            handleTabChange={handleTabChange}
                                            updateStatus={updateStatus}
                                            setShowAudioError={
                                                setShowAudioError
                                            }
                                            setOpenCompleteAction={
                                                setOpenCompleteAction
                                            }
                                            setOpenConfirmAction={
                                                setOpenConfirmAction
                                            }
                                        />
                                    </Footer>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </LoggedInComponent>
        </Layout>
    );
};

export default QCCaseReport;
