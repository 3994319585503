import { commonAxios } from "../utils/AxiosSettings";

export async function getCompanyDetails(company) {
    try {
        const res = await commonAxios.get(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/company/${company}`
        );

        return res;
    } catch (error) {
        if (error.response) {
            // The request was made, and the server responded with a status code that falls out of the range of 2xx
            const { status, data } = error.response;
            if (status === 500) {
                console.error(
                    "Server error: ",
                    data.message || "Internal server error"
                );
                return {
                    error: "Internal server error. Please try again later.",
                };
            } else {
                console.error(`Error ${status}: ${data.message}`);
                return { error: data.message || "An error occurred." };
            }
        } else if (error.request) {
            // The request was made, but no response was received
            console.error("No response received: ", error.request);
            return {
                error: "No response from server. Please check your network connection.",
            };
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error setting up the request: ", error.message);
            return { error: "Request error. Please try again." };
        }
    }
}
