import React from "react";
import _ from "lodash";
import { Tooltip } from "antd";
import DateTimeFormatter from "../components/Utils/DateTimeFormatter";
import { Input, Button } from "antd";
import { useState, useEffect } from "react";
import { companyDetailsState } from "../RecoilState/CompanyDetails";
import { useRecoilState } from "recoil";

export const getReopenTitle = (reopenType) => {
    switch (reopenType) {
        case "QC Reopen":
            return "Reopened by QC";
        case "CT Reopen":
            return "Reopened by CT";
        case "RA Reopen":
            return "Reopened by RA";
        default:
            return "Reopened Case";
    }
};

export default function useTableSchema(cases, mainTabKey, userRole) {
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [companyDetails] = useRecoilState(companyDetailsState);
    const searchDropdownVisible = (visible) => {
        if (visible) {
            setSelectedKeys([]);
        } else {
            setSelectedKeys([]);
        }
    };

    const searchDropdown = ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }) => {
        return (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder="Search case number"
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={confirm}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Button
                    type="primary"
                    onClick={confirm}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    Filter
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        confirm();
                    }}
                    size="small"
                    style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        );
    };

    const filterData =
        (cases) =>
        (formatter, uniqKey = "CaseNumber") => {
            if (uniqKey == "CaseStatus") {
                return _.uniqBy(cases, "CaseStatus").map((item, index) => {
                    return {
                        text: item.CaseStatus,
                        value: formatter(item),
                    };
                });
            } else if (uniqKey == "Date") {
                let newCases = cases.map((item, index) => {
                    return {
                        ...item,
                        Date: item.Date.split("T")[0],
                    };
                });
                return _.uniqBy(newCases, "Date").map((item, index) => {
                    return {
                        text: formatter(item).split("T")[0],
                        value: formatter(item).split("T")[0],
                    };
                });
            } else if (uniqKey == "Time") {
                return _.uniqBy(cases, "Time").map((item, index) => {
                    return {
                        text: formatter(item).split(".")[0],
                        value: formatter(item).split(".")[0],
                    };
                });
            } else {
                return _.uniqBy(cases, uniqKey).map((item) => ({
                    text: formatter(item),
                    value: formatter(item),
                }));
            }
        };

    const columns = [
        {
            key: "0",
            dataIndex: "priority__name",
            width: ".7%",
            maxwidth: "calc(20%)",
            render: (Priority) => {
                let color;
                if (
                    Priority === "High" ||
                    Priority === "Major Grievance (Level 1)" ||
                    Priority === "Major Grievance (Level 2)"
                ) {
                    color = companyDetails?.case_color_coding?.high;
                    return {
                        props: {
                            style: {
                                background:
                                    companyDetails?.case_color_coding?.high,
                            },
                        },
                        children: <div></div>,
                    };
                } else if (
                    Priority === "Medium" ||
                    Priority === "Medium Grievance (Internal)" ||
                    Priority === "Medium Grievance (External)"
                ) {
                    color = companyDetails?.case_color_coding?.medium;
                    return {
                        props: {
                            style: {
                                background:
                                    companyDetails?.case_color_coding?.medium,
                            },
                        },
                        children: <div></div>,
                    };
                } else if (
                    Priority === "Low" ||
                    Priority === "Minor Grievance (Internal)" ||
                    Priority === "Minor Grievance (External)"
                ) {
                    color = companyDetails?.case_color_coding?.low;
                    return {
                        props: {
                            style: {
                                background:
                                    companyDetails?.case_color_coding?.low,
                            },
                        },
                        children: <div></div>,
                    };
                } else {
                    return {
                        props: {
                            style: {
                                background:
                                    companyDetails?.case_color_coding
                                        ?.unclassified,
                            },
                        },
                        children: <div></div>,
                    };
                }
            },
        },
        {
            key: "1",
            title: "CASE NO.",
            dataIndex: "CaseNumber",
            width: "calc(10%)",
            filters: filterData(cases)((i) => i.CaseNumber),
            filterMode: "tree",
            sorter: (a, b) => a.id - b.id,
            filterSearch: true,
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const caseNumber = record.CaseNumber.toString()
                    .toLowerCase()
                    .trim();
                return caseNumber.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
            render: (text, record) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}>
                        <div
                            style={{
                                fontWeight:
                                    mainTabKey === "1" &&
                                    (record.CurrentStatus === "Unread" ||
                                        record.CurrentStatus === "new_cases") &&
                                    "bold",
                            }}>
                            {text}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}>
                            {userRole === "SUPER_ADMIN" ||
                            userRole === "REGIONAL_ADMIN" ? (
                                record.CurrentStatus === "Read" ||
                                record.CurrentStatus === "Draft" ? (
                                    <Tooltip title="Read">
                                        <span
                                            style={{
                                                color: "#E27A00",
                                                marginRight: "8px",
                                            }}>
                                            <img
                                                src="/assets/images/icon/adminDashboard/read.svg"
                                                alt="Read Case"
                                            />
                                        </span>
                                    </Tooltip>
                                ) : record.CurrentStatus === "Unread" ||
                                  record.CurrentStatus === "new_cases" ? (
                                    <Tooltip title="Unread">
                                        <span
                                            style={{
                                                color: "#E27A00",
                                                marginRight: "8px",
                                            }}>
                                            <img
                                                src="/assets/images/icon/adminDashboard/unread.svg"
                                                alt="Unread Case"
                                            />
                                        </span>
                                    </Tooltip>
                                ) : null
                            ) : null}
                            {(record.reopened == true ||
                                (record.reopenType &&
                                    record.reopenType !== "Not Reopened")) && (
                                <Tooltip
                                    title={() =>
                                        getReopenTitle(record.reopenType)
                                    }>
                                    <span
                                        style={{
                                            color: "#E27A00",
                                            marginRight: "8px",
                                        }}>
                                        <img
                                            src="/assets/images/CaseTable/reopen.svg"
                                            alt="Reopened Case"
                                        />
                                    </span>
                                </Tooltip>
                            )}
                            {record.CurrentStatus === "Draft" &&
                                mainTabKey === "1" && (
                                    <Tooltip title="Draft">
                                        <span style={{ color: "#E27A00" }}>
                                            Draft
                                        </span>
                                    </Tooltip>
                                )}
                        </div>
                    </div>
                );
            },
        },
        {
            key: "2",
            title: "FACTORY NAME",
            dataIndex: "Factory__Code",
            width: "calc(75% / 11)",
            filters: filterData(cases)((i) => i.Factory__Code, "Factory__Code"),
            filterMode: "tree",

            onFilter: (value, record) => {
                return record.Factory__Code.toString().includes(value);
            },
            render: (text) => text || null,
        },
        {
            key: "3",
            title: "MEDIUM",
            dataIndex: "ReportingMedium",
            width: "calc(85% / 11)",
            filters: filterData(cases)(
                (i) => i.ReportingMedium,
                "ReportingMedium"
            ),

            onFilter: (value, record) => {
                return record.ReportingMedium.toString().includes(value);
            },
            render: (text, record) => {
                return (
                    (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}>
                            <div>{text}</div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}>
                                {/* show Unregistered Call status only when medium is call */}
                                {record.Complainer__Registered === false &&
                                    record.ReportingMedium === "Call" && (
                                        <Tooltip title="Unregistered Call">
                                            <span
                                                style={{
                                                    color: "#E27A00",
                                                    marginRight: "8px",
                                                }}>
                                                <img src="/assets/images/CaseTable/callunregister.svg" />
                                            </span>
                                        </Tooltip>
                                    )}
                            </div>
                        </div>
                    ) || null
                );
            },
        },
        {
            key: "4",
            title: "DATE",
            dataIndex: "Date",
            width: "calc(85% / 11)",
            filters: filterData(cases)((i) => i.Date, "Date"),
            filterMode: "tree",
            render: (text, record) => {
                return <DateTimeFormatter dateTimeString={text} /> || null;
            },
            sorter: (a, b) =>
                new Date(a.Date).getTime() - new Date(b.Date).getTime(),
            onFilter: (value, record) => {
                return record.Date.toString().includes(value);
            },
        },

        {
            key: "6",
            title: "CATEGORY",
            dataIndex: "Category__category",
            width: "calc(85% / 11)",
            filters: filterData(cases)(
                (i) => i?.Category__category,
                "Category__category"
            ),
            filterMode: "tree",

            onFilter: (value, record) => {
                return record.Category__category?.toString().includes(value);
            },
            render: (text) => text || null,
        },
        {
            key: "7",
            title: "SUB CATEGORY",
            dataIndex: "Category__sub_category",
            width: "calc(85% / 11)",
            filters: filterData(cases)(
                (i) => i?.Category__sub_category,
                "Category__sub_category"
            ),
            filterMode: "tree",
            onFilter: (value, record) => {
                return record.Category__sub_category?.toString().includes(
                    value
                );
            },
            render: (text) => text || null,
        },

        {
            key: "8",
            title: "STATUS",
            dataIndex: "CaseStatus",
            width: "calc(85% / 11)",
            filters: filterData(cases)((i) => i.CaseStatus, "CaseStatus"),
            filterMode: "tree",
            render: (_, data) => {
                return data.CaseStatus
                    ? data.CaseStatus == "Closed with Positive Response"
                        ? "Positive Response"
                        : data.CaseStatus
                    : null;
            },

            onFilter: (value, record) => {
                return record.CaseStatus.toString().includes(value);
            },
        },
        {
            key: "9",
            title: "MANAGER",
            dataIndex: "CaseManager__user_name",
            width: "calc(85% / 11)",
            filters: filterData(cases)(
                (i) => i.CaseManager__user_name,
                "CaseManager"
            ),
            filterMode: "tree",
            render: (text, record) => {
                return record.CaseManager__user_name || null;
            },
            onFilter: (value, record) => {
                return record.CaseManager__user_name?.toString().includes(
                    value
                );
            },
        },
        {
            key: "10",
            title: "REPORTER",
            width: "calc(85% / 11)",
            dataIndex: "CaseReporter__user_name",
            filters: filterData(cases)(
                (i) => i.CaseReporter__user_name,
                "CaseReporter"
            ),
            filterMode: "tree",
            render: (text, data) => {
                return data.CaseReporter__user_name || null;
            },

            onFilter: (value, record) => {
                return record.CaseReporter__user_name?.toString().includes(
                    value
                );
            },
        },
        {
            key: "11",
            title: "TROUBLESHOOTER",
            width: "calc(85% / 11)",
            dataIndex: "CaseTroubleShooter__user_name",
            filters: filterData(cases)(
                (i) => i.CaseTroubleShooter__user_name,
                "CaseTroubleShooter"
            ),
            filterMode: "tree",
            render: (text, record) => {
                return record.CaseTroubleShooter__user_name || null;
            },
            onFilter: (value, record) => {
                return record.CaseTroubleShooter__user_name?.toString().includes(
                    value
                );
            },
        },
        {
            key: "12",
            title: "DUE DATE",
            width: "calc(85% / 11)",
            dataIndex: "dueDate",
            sorter: (a, b) => a.id - b.id,
            filterMode: "tree",
            render: (text, record) => {
                return (
                    (
                        <div
                            style={{
                                color: record.dueDateBreached ? "red" : "black",
                            }}>
                            {record.dueDateType === "timestamp" ? (
                                <DateTimeFormatter
                                    dateTimeString={record.dueDate}
                                />
                            ) : (
                                record.dueDate
                            )}
                        </div>
                    ) || null
                );
            },
        },
        {
            key: "13",
            title: "APPROVED ON",
            width: "calc(85% / 11)",
            dataIndex: "ApproveTime",
            filters: filterData(cases)((i) => i.ApproveTime, "ApproveTime"),
            filterMode: "tree",
            render: (text, record) => {
                return <DateTimeFormatter dateTimeString={text} /> || null;
            },
            sorter: (a, b) =>
                new Date(a.ApproveTime).getTime() -
                new Date(b.ApproveTime).getTime(),
            onFilter: (value, record) => {
                return record.ApproveTime.toString().includes(value);
            },
        },
        {
            key: "14",
            title: "REOPENED ON",
            width: "calc(85% / 11)",
            dataIndex: "created_at",
            filters: filterData(cases)((i) => i.created_at, "created_at"),
            filterMode: "tree",
            render: (text, record) => {
                return <DateTimeFormatter dateTimeString={text} /> || null;
            },
            sorter: (a, b) =>
                new Date(a.created_at).getTime() -
                new Date(b.created_at).getTime(),
            onFilter: (value, record) => {
                return record.created_at.toString().includes(value);
            },
        },
        {
            key: "15",
            title: "REASON",
            width: "calc(85% / 11)",
            dataIndex: "Reason",
            render: (text, record) => {
                if (!record.Reason) return null;

                return (
                    <div style={{ maxHeight: "70px", overflowY: "auto" }}>
                        {record.Reason.map((reason, index) => (
                            <span key={index}>
                                {index > 0 && <br />}- {reason}
                            </span>
                        ))}
                    </div>
                );
            },
        },
        {
            key: "16",
            title: "CLOSING TIME",
            dataIndex: "ClosingTime",
            width: "calc(85% / 11)",
            render: (text, record) => {
                return <DateTimeFormatter dateTimeString={text} /> || null;
            },
            sorter: (a, b) =>
                new Date(a?.ClosingTime).getTime() -
                new Date(b?.ClosingTime).getTime(),
            onFilter: (value, record) => {
                return record?.ClosingTime.toString().includes(value);
            },
        },
    ];

    return columns;
}
