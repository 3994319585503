import { commonAxios } from "../utils/AxiosSettings";

//api to get all the incentive data
export async function getTotalCaseData(startDate, endDate, factory, companyID) {
    const url = `${process.env.REACT_APP_BASE_URL_API}/api/accounts/cases/sepcount`;

    const queryParams = new URLSearchParams();

    if (startDate !== null) {
        queryParams.append("from", startDate);
    }
    if (endDate !== null) {
        queryParams.append("to", endDate);
    }
    if (factory !== null) {
        queryParams.append("Factory", factory);
    }
    if (companyID !== null) {
        queryParams.append("Company", companyID);
    }

    const res = await commonAxios.get(`${url}?${queryParams.toString()}`);

    return res;
}
export async function getWinnerOfFactoryByIncentive(companyID) {
    if (companyID !== null && companyID !== undefined) {
        let url = `${process.env.REACT_APP_BASE_URL_API}/api/accounts/winnerIncentives?Company=${companyID}`;
        const res = commonAxios.get(url);
        return res;
    }
}

export async function getEligibilityCriteria(companyID) {
    if (companyID !== null && companyID !== undefined) {
        let url = `${process.env.REACT_APP_BASE_URL_API}/api/accounts/criteria/?company=${companyID}`;
        const res = commonAxios.get(url);
        return res;
    }
}
export async function postEligibilityCriteria(data) {
    let url = `${process.env.REACT_APP_BASE_URL_API}/api/accounts/criteria/`;
    const res = commonAxios.post(url, data);
    return res;
}
