import React, { useState, useEffect } from "react";
import { Form, Input, Row, Col, message, Divider, DatePicker } from "antd";
import UploadComponent from "../../../components/UploadComponent/UploadHeaderFile";
import { regionState } from "../../../RecoilState/regionState";
import { useRecoilState } from "recoil";
import useFetchRegion from "../../../components/AdminDashboards/RegionDashboard/RegionHooks/FetchRegionHook";
import {
    addPolicy,
    deletePolicy,
    editPolicy,
} from "../../../Adapters/PolicyDashboard";
import { userState } from "../../../RecoilState/userState";
import { getAllRoles } from "../../../Adapters/AdminCalls";
import { tokenState } from "../../../RecoilState/tokenState";
import { FactoryData } from "../../../RecoilState/FactoryData";
import styles from "../Policy.module.css";
import MultiSelectDropDown from "../../../components/Utils/MultiSelectDropDown";
import dayjs from "dayjs";
const AddPolicy = ({
    setShowAddEditUser,
    recordData,
    isEditMode,
    setIsEditMode,
    refreshFile,
}) => {
    const [form] = Form.useForm();
    const [user] = useRecoilState(userState);
    const [token] = useRecoilState(tokenState);
    const [regions] = useRecoilState(regionState);
    const [FactoryList] = useRecoilState(FactoryData);
    const { fetchRegionData, data, loading, error } = useFetchRegion();

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [policyId, setPoloicyId] = useState("");
    const [formData, setFormData] = useState(recordData || {});

    const [handleUpload, setHandleUpload] = useState(false);
    const [disableDoc, setDisableDoc] = useState(false);
    const [disableLink, setDisableLink] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    //dropdown props states
    const [selectedRegionData, setSelectedRegionData] = useState([]);
    const [selectedFactoryData, setSelectedFactoryData] = useState([]);
    const [userRoles, setUsersRole] = useState([]);
    const [regionDropdownShow, setRegionDropdownShow] = useState(false);
    const [factoryDropdownShow, setFactoryDropdownShow] = useState(false);
    const [userDropdownShow, setUserDropdownShow] = useState(false);
    const [updateFlag, setUpdateFlag] = useState(false);

    function getRegionIds(regionNames) {
        return regionNames
            .map((name) => {
                const region = regions.find((region) => region.Name === name);
                return region ? region.id : null;
            })
            .filter((id) => id !== null);
    }
    const regionIds = getRegionIds(selectedRegionData);
    function getFactoryNames(regionIds) {
        return regions.length
            ? regions
                  .filter((region) => regionIds.includes(region.id))
                  .flatMap((region) =>
                      region.Factories.map((factory) => factory.Code)
                  )
            : FactoryList.map((item) => item.Code);
    }
    const factoryNames = getFactoryNames(regionIds);
    function getFactoryIds(factoryCodes) {
        return regions.length
            ? regions
                  .flatMap((region) => region.Factories)
                  .filter((factory) => factoryCodes.includes(factory.Code))
                  .map((factory) => factory.id)
            : FactoryList.filter((item) =>
                  selectedFactoryData.includes(item.Code)
              ).map((item) => item.id);
    }

    const factoryIds = getFactoryIds(selectedFactoryData);

    useEffect(() => {
        form.setFieldsValue({
            policyName: formData.policy_name,
            regionName: formData.regions,
            link: formData.attached_link,
            factoryName: formData.factories,
            user: formData.user_roles,
            expirationdate:
                formData?.expiration_date &&
                dayjs(formData.expiration_date).isValid()
                    ? dayjs(formData.expiration_date)
                    : null,
        });
        formData?.attached_link && setDisableDoc(true);
        formData.length > 0 &&
            formData?.attachment.length > 0 &&
            setDisableLink(true);
        setPoloicyId(formData?.id);
    }, [formData]);

    const resetFormData = () => {
        setUploadedFiles([]);
        setPoloicyId("");
    };

    useEffect(() => {
        setUploadedFiles(formData.attachment || []);
        if (formData?.attachment?.length > 0) {
            setDisableLink(true);
        }
    }, [formData, recordData]);

    useEffect(() => {
        form.setFieldsValue({ file: uploadedFiles });
        if (uploadedFiles.length == []) {
            setDisableLink(false);
        }
    }, [uploadedFiles]);

    const onUploadComplete = () => {
        setShowAddEditUser(false);
        resetFormData();
        setIsEditMode(false);
    };

    const handleSubmit = async (values) => {
        const data = {
            id: formData.id,
            company_fk: user.company_fk,
            policy_name: values.policyName,
            attached_link: values.link,
            regions: regionIds.length ? regionIds : [user?.region_fk],
            factories: factoryIds,
            user_roles: selectedUsers,
            expiration_date: dayjs(values.expirationdate).format("YYYY-MM-DD"),
        };

        let response;
        try {
            if (isEditMode) {
                setHandleUpload(true);
                response = await editPolicy(data);
            } else {
                response = await addPolicy(data);
                setPoloicyId(response.data.id);
                setHandleUpload(true);
            }

            message.success(response.data.message);
        } catch (error) {
            message.error(
                error.response?.data?.errorMessage
                    ? error.response.data.errorMessage
                    : "Failed to save policy. Try again!"
            );
            throw error;
        }
        if (!disableLink && disableDoc) {
            setShowAddEditUser(false);
            setIsEditMode(false);
            resetFormData();
        } else if (formData?.attachment?.length > 0 && disableLink) {
            setShowAddEditUser(false);
            setIsEditMode(false);
            resetFormData();
        }
    };

    const handleDelete = async () => {
        try {
            const response = await deletePolicy(formData.id);
            message.success(response.data.message);
            setShowAddEditUser(false);
            setIsEditMode(false);
            resetFormData();
        } catch (error) {
            message.error(
                error.response?.data?.errorMessage
                    ? error.response.data.errorMessage
                    : "Failed to delete policy. Try again!"
            );
        }
    };

    const regionOptions = regions.map((region) => region.Name);

    const roleOptions = userRoles.map((role) => role);

    useEffect(() => {
        if (isEditMode) {
            if (user.role === "REGIONAL_ADMIN") {
                setSelectedRegionData([user.region]);
            } else {
                const regionIds = formData.regions;
                const regionNames = regions
                    .filter((region) => regionIds.includes(region.id))
                    .map((region) => region.Name);
                setSelectedRegionData(regionNames);
            }

            const factoryIds = formData.factories;
            const factoryNames = regions.length
                ? regions
                      .flatMap((region) => region.Factories)
                      .filter((factory) => factoryIds.includes(factory.id))
                      .map((factory) => factory.Code)
                : FactoryList.filter((factory) =>
                      recordData.factories.includes(factory.id)
                  ).map((item) => item.Code);

            setSelectedFactoryData(factoryNames);

            const filteredUserRoles = userRoles
                .map((role) => role)
                .filter((role) => {
                    return formData.user_roles.includes(role);
                });

            setSelectedUsers(filteredUserRoles);
        }
    }, [isEditMode, userRoles]);

    // Update form data when recordData changes
    useEffect(() => {
        setFormData(recordData || {});
    }, [recordData]);

    useEffect(() => {
        if (user.role !== "REGIONAL_ADMIN" && regions.length === 0) {
            fetchRegionData();
        }
    }, [user.role, regions, fetchRegionData]);

    useEffect(() => {
        const fetchRoles = async () => {
            if (token.access) {
                try {
                    const res = await getAllRoles(token, "policy");

                    setUsersRole(res.data.Roles);
                } catch (error) {
                    console.log(error);
                }
            }
        };
        fetchRoles();
        if (user.role === "REGIONAL_ADMIN") {
            setSelectedRegionData([user.region]);
        } else {
            const regionIds = formData.regions;
            const regionNames = regions
                .filter((region) => regionIds?.includes(region.id))
                .map((region) => region.Name);
            setSelectedRegionData(regionNames);
        }
    }, []);

    const title = isEditMode ? "Edit Policy" : "Add Policy";

    function filterSelectedFactoryData(selectedFactoryData, factoryNames) {
        return selectedFactoryData.filter((factory) =>
            factoryNames.includes(factory)
        );
    }
    const filteredSelectedFactoryData = filterSelectedFactoryData(
        selectedFactoryData,
        factoryNames
    );

    useEffect(() => {
        if (selectedFactoryData.length > 0) {
            setSelectedFactoryData(filteredSelectedFactoryData);
            form.setFieldsValue({
                factoryName: filteredSelectedFactoryData,
            });
        }
    }, [updateFlag]);
    useEffect(() => {
        regionDropdownShow && setFactoryDropdownShow(false);
    }, [regionDropdownShow]);

    return (
        <div
            className={styles.customBox}
            onClick={() => (
                setFactoryDropdownShow(false),
                setRegionDropdownShow(false),
                setUserDropdownShow(false)
            )}>
            <Row className={styles.container}>
                <Col span={24}>
                    <Row className={styles.header}>
                        <Col flex={1} className={styles.titleCol}>
                            <a
                                onClick={() => {
                                    setShowAddEditUser(false);
                                    setIsEditMode(false);
                                    resetFormData();
                                }}
                                className={styles.backLink}>
                                <img
                                    src="/assets/images/back/Group4495.svg"
                                    alt="back"
                                />
                            </a>
                        </Col>
                        <Col flex={20} className={styles.title}>
                            <h1>{title}</h1>
                        </Col>
                    </Row>
                    <Row style={{ width: "100%" }}>
                        <Col style={{ width: "100%" }}>
                            <Form form={form} onFinish={handleSubmit}>
                                <Row gutter={16} style={{ width: "100%" }}>
                                    <Col style={{ width: "50%" }}>
                                        <Form.Item
                                            label="Policy Name"
                                            name="policyName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please enter a Policy Name",
                                                },
                                            ]}>
                                            <Input.TextArea
                                                rows={4}
                                                placeholder="Define the Policy Name"
                                                maxLength={30}
                                                showCount
                                                className={styles.textArea}
                                            />
                                        </Form.Item>
                                        <Divider />
                                        <h3 className={styles.subheading}>
                                            Applicable to:
                                        </h3>
                                        {user.role !== "REGIONAL_ADMIN" && (
                                            <Form.Item
                                                label="Region/s"
                                                name="regionName"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Please enter a region name",
                                                    },
                                                ]}>
                                                <MultiSelectDropDown
                                                    dropdownShow={
                                                        regionDropdownShow
                                                    }
                                                    setDropdownShow={
                                                        setRegionDropdownShow
                                                    }
                                                    labelData={regionOptions}
                                                    disabled={false}
                                                    selectedData={
                                                        selectedRegionData
                                                    }
                                                    setSelectedData={(
                                                        newData
                                                    ) => {
                                                        setSelectedRegionData(
                                                            newData
                                                        );
                                                        form.setFieldsValue({
                                                            regionName: newData,
                                                        });
                                                    }}
                                                    showConfirmation={true}
                                                    setUpdateFlag={
                                                        setUpdateFlag
                                                    }
                                                    updateFlag={updateFlag}
                                                />
                                            </Form.Item>
                                        )}
                                        <Form.Item
                                            label="Factory/s"
                                            name="factoryName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please enter a Factory name",
                                                },
                                            ]}>
                                            <MultiSelectDropDown
                                                dropdownShow={
                                                    factoryDropdownShow
                                                }
                                                setDropdownShow={
                                                    setFactoryDropdownShow
                                                }
                                                labelData={factoryNames}
                                                disabled={false}
                                                selectedData={
                                                    selectedFactoryData
                                                }
                                                setSelectedData={(newData) => {
                                                    setSelectedFactoryData(
                                                        newData
                                                    );
                                                    form.setFieldsValue({
                                                        factoryName: newData,
                                                    });
                                                }}
                                                showConfirmation={false}
                                                setUpdateFlag={setUpdateFlag}
                                                updateFlag={updateFlag}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="User/s"
                                            name="user"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please enter a user name",
                                                },
                                            ]}>
                                            <MultiSelectDropDown
                                                dropdownShow={userDropdownShow}
                                                setDropdownShow={
                                                    setUserDropdownShow
                                                }
                                                labelData={roleOptions}
                                                disabled={false}
                                                selectedData={selectedUsers}
                                                setSelectedData={(newData) => {
                                                    setSelectedUsers(newData);
                                                    form.setFieldsValue({
                                                        user: newData,
                                                    });
                                                }}
                                                showConfirmation={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col style={{ width: "50%" }}>
                                        <Form.Item
                                            label="Document"
                                            name="file"
                                            rules={[
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        const allowedFileTypes =
                                                            ["pdf"];
                                                        const currentFileList =
                                                            getFieldValue(
                                                                "file"
                                                            );
                                                        if (
                                                            !currentFileList ||
                                                            currentFileList.length ===
                                                                0
                                                        ) {
                                                            return Promise.resolve();
                                                        }
                                                        for (let file of currentFileList) {
                                                            if (
                                                                typeof file ===
                                                                "number"
                                                            ) {
                                                                return Promise.resolve();
                                                            }
                                                            const fileType =
                                                                file.name
                                                                    .split(".")
                                                                    .slice(
                                                                        -1
                                                                    )[0];
                                                            if (
                                                                !allowedFileTypes.includes(
                                                                    fileType.toLowerCase()
                                                                )
                                                            ) {
                                                                return Promise.reject(
                                                                    new Error(
                                                                        "File type not allowed!"
                                                                    )
                                                                );
                                                            }
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                                {
                                                    required: !disableDoc,
                                                    message:
                                                        "Please upload a document!",
                                                },
                                            ]}>
                                            <UploadComponent
                                                data-testid="document-upload"
                                                setDisableLink={setDisableLink}
                                                disableUpload={disableDoc}
                                                document_delete_on={true}
                                                externalFileList={uploadedFiles}
                                                Files={
                                                    formData?.attachment || []
                                                }
                                                maxFiles="1"
                                                allowedFileTypes={["pdf"]}
                                                companyFk={user.company_fk}
                                                model="policies"
                                                modelMethod="new"
                                                onModelCreated={handleUpload}
                                                setOnModalCreated={
                                                    setHandleUpload
                                                }
                                                onExternalFileListChange={(
                                                    newFileList
                                                ) =>
                                                    setUploadedFiles(
                                                        newFileList
                                                    )
                                                }
                                                onUploadComplete={
                                                    onUploadComplete
                                                }
                                                id={policyId}
                                                refresh={refreshFile}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Link"
                                            name="link"
                                            rules={[
                                                {
                                                    required: !disableLink,
                                                    message:
                                                        "Please enter a Link",
                                                },
                                                {
                                                    pattern: new RegExp(
                                                        '^(ftp|http|https):\\/\\/[^ "]+$'
                                                    ),
                                                    message:
                                                        "Please enter a valid URL",
                                                },
                                            ]}>
                                            <Input
                                                disabled={disableLink}
                                                onChange={(e) => {
                                                    e.target.value.length > 0
                                                        ? setDisableDoc(true)
                                                        : setDisableDoc(false);
                                                }}
                                                placeholder="Enter the link"
                                                className={styles.input}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Expiration Date"
                                            name="expirationdate">
                                            <DatePicker
                                                className={styles.textArea}
                                                disabledDate={(current) =>
                                                    current &&
                                                    current <
                                                        dayjs().startOf("day")
                                                }
                                                format="DD-MM-YYYY"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row justify="end" gutter={16}>
                                    <Col>
                                        <button
                                            type="button"
                                            className="secondaryButton"
                                            onClick={() => {
                                                setShowAddEditUser(false);
                                                setIsEditMode(false);
                                                resetFormData();
                                            }}>
                                            Cancel
                                        </button>
                                    </Col>
                                    {isEditMode && (
                                        <Col>
                                            <button
                                                type="button"
                                                className="secondaryButton"
                                                onClick={handleDelete}>
                                                Delete
                                            </button>
                                        </Col>
                                    )}
                                    <Col>
                                        <button
                                            type="submit"
                                            className="primaryButton">
                                            Save
                                        </button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default AddPolicy;
