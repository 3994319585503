import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useEffect } from "react";
import { commonAxios } from "../../../../utils/AxiosSettings";
import { Input, message } from "antd";
import { userState } from "../../../../RecoilState/userState";
import { DeleteOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { tokenState } from "../../../../RecoilState/tokenState";
import { FactoryData } from "../../../../RecoilState/FactoryData";
import { getAllFactoriesOfCompany } from "../../../../Adapters/FactoryAndCompanyCalls";
import styles from "../../AdminDashboard.module.css";
import useEventMetricsUpdater from "../../../../hooks/useEventMetricsUpdater";
import useFetchRegion from "../../RegionDashboard/RegionHooks/FetchRegionHook";

const DeleteFactoryButton = ({ props }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [user, setUser] = useRecoilState(userState);
    const [token, setToken] = useRecoilState(tokenState);
    const [factoryList, setFactoryList] = useRecoilState(FactoryData);
    const { updateEventMetrics } = useEventMetricsUpdater();
    const { fetchRegionData } = useFetchRegion();
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = (eventName, actionType) => {
        handleConfirm();
        setIsModalVisible(false);
        refreshData();
        refreshField();
        updateEventMetrics(eventName, actionType);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        refreshData();
        refreshField();
    };

    function refreshData() {
        getAllFactoriesOfCompany(
            props.Company_id,
            token.access,
            user.role === "REGIONAL_ADMIN" && "region"
        ).then((res) => {
            setFactoryList(res.data.message_body.Factories);
        });
    }

    function refreshField() {
        setConfirmInput("");
    }

    const [confirmInput, setConfirmInput] = useState("");
    const [assignFactoryState, setAssignFactoryState] = useState(false);

    function handleConfirm() {
        if (confirmInput === "Delete") {
            commonAxios({
                url: `${process.env.REACT_APP_BASE_URL_API}/api/accounts/factories/?id=${props.id}`,
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response?.status === 200) {
                        message.success(response?.data?.message);
                        setIsModalVisible(false);
                        refreshData();
                        fetchRegionData();
                        refreshField();
                    } else {
                        message.warning(
                            "There was a problem deleting the Factory."
                        );
                        refreshData();
                        refreshField();
                    }
                })
                .catch((error) => {
                    if (error?.response?.status === 403) {
                        message.error(error?.response?.data?.errorMessage);
                        setAssignFactoryState(true);
                        refreshData();
                        refreshField();
                    } else if (error?.response?.status === 404) {
                        message.error(error?.response?.data?.errorMessage);
                        refreshData();
                        refreshField();
                    } else {
                        message.error(error?.response?.data?.errorMessage);
                        refreshData();
                        refreshField();
                    }
                });
        } else {
            message.error("Incorrect Input");
        }
    }
    function handleAssignOK() {
        setIsModalVisible(false);
        refreshData();
        refreshField();
    }
    if (assignFactoryState === true) {
        return (
            <>
                <Button
                    icon={<DeleteOutlined />}
                    type="text"
                    onClick={showModal}></Button>
                <Modal
                    title="Delete Factory"
                    open={isModalVisible}
                    onOk={handleAssignOK}
                    footer={[
                        <Button
                            key="ok"
                            onClick={handleAssignOK}
                            className={styles.solidButton}>
                            Save Change
                        </Button>,
                    ]}
                    width={300}
                    closeIcon>
                    <div>
                        <h3>Can’t Delete Factory with Pending cases</h3>
                        <p>
                            The selected Factory has pending cases left. Please
                            clear those before deleting the Factory data.
                        </p>
                    </div>
                </Modal>
            </>
        );
    } else {
        return (
            <>
                <Button
                    icon={<DeleteOutlined />}
                    type="text"
                    onClick={showModal}></Button>
                <Modal
                    title="Delete Factory"
                    open={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                        <Button
                            key="cancel"
                            onClick={handleCancel}
                            className={styles.outlineButton}>
                            Cancel
                        </Button>,
                        <Button
                            key="ok"
                            onClick={() =>
                                handleOk(
                                    "deleteFactory",
                                    "Delete Factory - Confirm Delete factory, Okay"
                                )
                            }
                            className={styles.solidButton}>
                            Okay
                        </Button>,
                    ]}
                    width={300}
                    closeIcon>
                    <div>
                        <h3>
                            ALL THE DATA OF {props.Code} WILL BE DELETED
                            PERMANENTLY.
                        </h3>
                        <p>Type Delete</p>
                        <Input
                            label="Type Delete"
                            placeholder="Type here"
                            value={confirmInput}
                            onChange={(e) =>
                                setConfirmInput(e.target.value)
                            }></Input>
                    </div>
                </Modal>
            </>
        );
    }
};

export default DeleteFactoryButton;
