import React, { useState, useEffect } from "react";
import { getCases } from "../../../../Adapters/TableDataByUser";
import { Input, Tabs } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import useTableSchema from "../../../../hooks/useTableSchema";
import NewCaseTable from "./NewCaseTable";
import InProgressCaseTable from "./InProgressCaseTable";
import ResolvedCaseTable from "./ResolvedCaseTable";
import ClosedCaseTable from "./ClosedCaseTable";
import { getCaseDetailsByID } from "../../../../Adapters/CaseCRUDCalls";
import { caseReportDataState } from "../../../../RecoilState/caseReportDataState";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { valueUpdateFlag } from "../../../../RecoilState/valueUpdateFlag";
import { flagState } from "../../../../RecoilState/flagState";
import { currentSelectedRoleState } from "../../../../RecoilState/GlobalUserRoleStates/currentSelectedRoleState";
import { factoryState } from "../../../../RecoilState/FactoryState";
import { previousRouteData } from "../../../../RecoilState/previousRouteData";
import { FactoryData } from "../../../../RecoilState/FactoryData";
import useEventMetricsUpdater from "../../../../hooks/useEventMetricsUpdater";
import ReopenedCaseTable from "./ReopenedCaseTable";
import ApprovedCaseTable from "./ApprovedCaseTable";
import { dashboardData } from "../../../../RecoilState/dashboardData";
import { updateQCIntroViewed } from "../../../../Adapters/QCIntroCalls";
import { companyDetailsState } from "../../../../RecoilState/CompanyDetails";
import styles from "./TableData.module.css";

export default function TableData({
    tourData,
    setTourData,
    user,
    tourRef,
    factories,
}) {
    const location = useLocation();
    const [previousRoute, setPreviousRoute] = useRecoilState(previousRouteData);
    const [FactoryList, setFactoryList] = useRecoilState(FactoryData);
    const [companyDetails] = useRecoilState(companyDetailsState);
    const [caseDatastate, setcaseDatastate] =
        useRecoilState(caseReportDataState);
    const [alldashboardData, setdashboardData] = useRecoilState(dashboardData);
    const [FactorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [updateValue, setUpdateValue] = useRecoilState(valueUpdateFlag);
    const [currentSelectedRole, setCurrentSelectedRole] = useRecoilState(
        currentSelectedRoleState
    );
    const [flag, setFlagState] = useRecoilState(flagState);
    const [mainTabKey, setMainTabKey] = useState();
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("");
    const [cases, setCases] = useState([]);
    const [pageNumber, setPageNumber] = useState(null);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [filteredTableData, setFilteredTableData] = useState([]);
    const { updateEventMetrics } = useEventMetricsUpdater();

    const isCritical = searchParams.get("critical");

    const onTabChange = (key) => {
        setPreviousRoute((prev) => ({
            defaultRoute: prev.defaultRoute,
            activeTab: key,
        }));
        setCases([]);
        setMainTabKey(key);
        setPageNumber(1);
    };
    const tabMap = {
        1: "new_cases",
        2: "in_progress_cases",
        3: "resolved_cases",
        4: "closed_cases",
        5: "approved_cases",
        6: "reopened_cases",
    };

    const columns = useTableSchema(cases, previousRoute.activeTab, user?.role);

    const filteredColumns = columns.filter((column) => {
        // check if any row in dataSource has non-empty value for the column's dataIndex
        return cases.some((data) => data[column.dataIndex]);
    });

    const handleRowClick = (id) => {
        if (tourData.displayIntro) {
            updateQCIntroViewed(false)
                .then((res) => {
                    setTourData((tour) => ({
                        ...tour,
                        endTour: true,
                    }));
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        setUpdateValue(!updateValue);
        getCaseDetailsByID(id).then((res) => {
            setcaseDatastate(res.data);
            //this is a temp code this will further change based on development
            setPageNumber(location?.state?.pageNumber);
            user.role === "QC"
                ? navigate(`/CaseReportQC/${id}`, {
                      state: { myData: res.data, pageNumber },
                  })
                : navigate(`/GeneralCaseReport/${id}`, {
                      state: { myData: res.data, pageNumber },
                  });
        });
        updateEventMetrics(
            "caseClick",
            "Case clicked from the dashborad",
            "caseID",
            id
        );
    };

    const onSearch = (value) => {
        const filterTableData = cases.filter((record) => {
            return Object.keys(record).some((key) =>
                String(record[key]).toLowerCase().includes(value.toLowerCase())
            );
        });
        setFilteredTableData(filterTableData);
    };

    useEffect(() => {
        if (tourData.open && mainTabKey !== "1") {
            onTabChange("1");
            setCases([]);
        }
    }, [tourData.open]);

    useEffect(() => {
        if (tourData.flag) {
            handleRowClick(cases[0]?.id);
        }
    }, [tourData.flag]);

    useEffect(() => {
        let key = previousRoute.activeTab;
        if (location?.state?.tabKey) {
            key = location?.state.tabKey;
        } else if (!previousRoute.activeTab) {
            key = !isCritical || isCritical === "true" ? "1" : "2";
        }
        setPreviousRoute((prev) => ({
            defaultRoute: location.pathname + location.search,
            activeTab: key,
        }));
        setMainTabKey(key);
    }, [location]);

    useEffect(() => {
        setPageNumber(location?.state?.pageNumber);
        // If FactorySelected already has a value, do nothing
        if (FactorySelected) return;

        // If user is SUPER_ADMIN or REGIONAL_ADMIN and FactorySelected is not set
        if (
            ["SUPER_ADMIN", "REGIONAL_ADMIN", "LEAD_SUPERVISOR"].includes(
                user?.role
            )
        ) {
            setFactorySelected(FactoryList[0]);
        } else {
            // Otherwise, set FactorySelected based on the user's factory_fk
            const selectedFactory =
                FactoryList &&
                FactoryList?.find((f) => f.id === user.factory_fk);
            if (selectedFactory) {
                setFactorySelected(selectedFactory);
            }
        }
    }, [FactoryList, FactorySelected, user]);

    useEffect(() => {
        setActiveTab(tabMap[mainTabKey] || "default");
        setFilteredTableData([]);
    }, [user, mainTabKey]);

    const fetchCases = async () => {
        const factoryID = [
            "SUPER_ADMIN",
            "REGIONAL_ADMIN",
            "LEAD_SUPERVISOR",
        ].includes(user?.role)
            ? !factories.length
                ? [FactorySelected?.id]
                : factories.map((factory) => factory.id)
            : user.factory_fk;

        try {
            const res = await getCases(
                isCritical,
                activeTab,
                factoryID,
                user.company_fk
            );

            setLoading(false);
            setCases(res?.data?.cases);

            if (activeTab == "new_cases") {
                setTourData((tour) => ({
                    ...tour,
                    endTour: res.data?.cases.length == 0 ? true : false,
                }));
            }

            setdashboardData(res.data);
        } catch (error) {
            console.log(error.message);
            setCases([]);
        }
    };

    useEffect(() => {
        if (user && (currentSelectedRole != null || undefined || "")) {
            setLoading(true);

            //need to refactor this code based on User RA aand critical false as there we will not have factoryFK
            if (
                (FactorySelected?.id || user?.factory_fk) &&
                activeTab != "default"
            ) {
                fetchCases();
            }
        }
    }, [
        user,
        activeTab,
        flag,
        user.factory_fk,
        FactorySelected?.id,
        factories,
    ]);

    const items = [
        user?.role !== "SUPER_ADMIN" &&
            user?.role !== "FACTORY_ADMIN" &&
            user?.role !== "LEAD_SUPERVISOR" &&
            location.search !== "?critical=false" && {
                key: "1",
                label: <label ref={tourRef.newRef}>New / Active</label>,
                children: (
                    <NewCaseTable
                        caseRowRef={tourRef.caseRowRef}
                        loading={loading}
                        columns={filteredColumns}
                        cases={cases}
                        handleRowClick={handleRowClick}
                        filterTableData={filteredTableData}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                    />
                ),
            },
        // In-progress tab will not be visible in CTs dashboard
        user.role !== "CT" &&
            user.role !== "QC" &&
            location.search !== "?critical=true" && {
                key: "2",
                label: `In-progress`,
                children: (
                    <InProgressCaseTable
                        loading={loading}
                        columns={filteredColumns}
                        cases={cases}
                        handleRowClick={handleRowClick}
                        filterTableData={filteredTableData}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                    />
                ),
            },
        user.role !== "SUPER_ADMIN" &&
            user.role !== "FACTORY_ADMIN" &&
            user.role !== "QC" &&
            user?.role !== "LEAD_SUPERVISOR" &&
            location.search !== "?critical=false" && {
                key: "3",
                label: `Resolved`,
                children: (
                    <ResolvedCaseTable
                        loading={loading}
                        columns={filteredColumns}
                        cases={cases}
                        handleRowClick={handleRowClick}
                        filterTableData={filteredTableData}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                    />
                ),
            },
        user.role !== "QC" && {
            key: "4",
            label: `Closed`,
            children: (
                <ClosedCaseTable
                    loading={loading}
                    columns={filteredColumns}
                    cases={cases}
                    handleRowClick={handleRowClick}
                    filterTableData={filteredTableData}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                />
            ),
        },
        (user.role === "QC" ||
            ((user.role === "SUPER_ADMIN" ||
                user.role === "FACTORY_ADMIN" ||
                user?.role === "LEAD_SUPERVISOR" ||
                user.role === "REGIONAL_ADMIN") &&
                location.search === "?critical=false")) && {
            key: "5",
            label: <label ref={tourRef.approveRef}>Approved</label>,
            children: (
                <ApprovedCaseTable
                    loading={loading}
                    columns={filteredColumns}
                    cases={cases}
                    handleRowClick={handleRowClick}
                    filterTableData={filteredTableData}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                />
            ),
        },
        user.role === "QC" && {
            key: "6",
            label: <label ref={tourRef.reopenRef}>Reopened</label>,
            children: (
                <ReopenedCaseTable
                    loading={loading}
                    columns={filteredColumns}
                    cases={cases}
                    handleRowClick={handleRowClick}
                    filterTableData={filteredTableData}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                />
            ),
        },
    ];

    return (
        <>
            <div className={styles.TableData}>
                <div
                    style={{
                        position: "relative",
                        minHeight: "90%",
                    }}>
                    <Tabs
                        activeKey={mainTabKey || "1"}
                        items={items}
                        onChange={onTabChange}
                    />
                    <div style={{ position: "absolute", top: 7, right: 10 }}>
                        <Input
                            placeholder="Search Table"
                            prefix={<SearchOutlined />}
                            allowClear
                            onChange={(e) => onSearch(e.target.value)}
                            style={{
                                width: 200,
                                marginRight: 8,
                                border: 0,
                                borderBottom: "1px solid #1B2559",
                            }}
                        />
                    </div>
                </div>

                {(mainTabKey === "1" && user.role === "CR") ||
                user.role === "REGIONAL_ADMIN" ||
                user.role === "QC" ? null : (
                    <div className={styles.PriorityDisplay}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "10px",
                            }}></div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "10px",
                            }}>
                            <div
                                style={{
                                    background:
                                        companyDetails?.case_color_coding?.high,
                                    width: "8px",
                                    height: "8px",
                                    borderRadius: "50%",
                                    margin: "5px",
                                }}></div>
                            High Priority
                        </div>

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "10px",
                            }}>
                            <div
                                style={{
                                    background:
                                        companyDetails?.case_color_coding
                                            ?.medium,
                                    width: "8px",
                                    height: "8px",
                                    borderRadius: "50%",
                                    margin: "5px",
                                }}></div>
                            Medium Priority
                        </div>

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "10px",
                            }}>
                            <div
                                style={{
                                    background:
                                        companyDetails?.case_color_coding?.low,
                                    width: "8px",
                                    height: "8px",
                                    borderRadius: "50%",
                                    margin: "5px",
                                }}></div>
                            Low Priority
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
