import { commonAxios } from "../utils/AxiosSettings";

export async function AwarenessProgrammeData(param, DateString, factoryID) {
    if (param && DateString && factoryID) {
        let queryUrl = new URL(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/awareness/?${param}=${DateString}`
        );
        queryUrl.searchParams.append("Factory", factoryID);
        const res = await commonAxios.get(queryUrl);

        return res;
    }
}
export async function AwarenessProgrammeDataByID(programNumber) {
    const res = await commonAxios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/awareness/?programNumber=${programNumber}`
    );

    return res;
}
