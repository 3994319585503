import { Select, Spin } from "antd";
import React from "react";
import { useRecoilState } from "recoil";
import { userState } from "../../../RecoilState/userState";

const RegionDropdown = ({ RegionData, region, setRegion, setRegionId }) => {
    const [user] = useRecoilState(userState);

    return (
        <>
            {region ? (
                <Select
                    style={{ width: "95%" }}
                    value={`${region} Region`}
                    disabled={user.role === "REGIONAL_ADMIN"}
                    onChange={(value, option) => {
                        // The option object contains the full data passed in options
                        const selectedRegion = RegionData.find(
                            (r) => `${r.Name} Region` === value
                        );
                        if (selectedRegion) {
                            setRegion(selectedRegion.Name);
                            setRegionId(selectedRegion.id);
                        }
                    }}
                    options={RegionData.map((region, index) => ({
                        value: `${region.Name} Region`,
                        label: `${region.Name} Region`,
                        key: index,
                    }))}
                />
            ) : (
                <Spin />
            )}
        </>
    );
};

export default RegionDropdown;
