import { Layout, Row, Col } from "antd";
import React from "react";
import { Card, Typography } from "antd";
import {
    GiftOutlined,
    CalendarOutlined,
    MessageOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import styles from "./AdminDashboard.module.css";
import { useRecoilState } from "recoil";
import { userState } from "../../RecoilState/userState.js";
import { previousRouteData } from "../../RecoilState/previousRouteData.js";
const { Title } = Typography;

const { Meta } = Card;

const formatTitle = (conditions) => {
    const titles = conditions
        .filter((condition) => condition.check)
        .map((condition) => condition.title);

    if (titles.length > 1) {
        return `${titles.slice(0, -1).join(", ")} and ${
            titles[titles.length - 1]
        }`;
    }

    return titles.join("");
};

const AdminDashboard = () => {
    const [user, setUser] = useRecoilState(userState);
    const [previousRoute, setPreviousRoute] = useRecoilState(previousRouteData);
    const key =
        user?.group_permissions?.includes("add_factoryregion") ||
        user?.group_permissions?.includes("view_factoryregion")
            ? "1"
            : user?.group_permissions?.includes("add_factory") ||
              user?.group_permissions?.includes("view_factory")
            ? "2"
            : user?.group_permissions?.includes("change_baseusermodel") ||
              user?.group_permissions?.includes("view_baseusermodel")
            ? "3"
            : null;
    const formatTitle = (conditions) => {
        const titles = conditions
            .filter((condition) => condition.check)
            .map((condition) => condition.title);

        if (titles.length > 1) {
            return `${titles.slice(0, -1).join(", ")} and ${
                titles[titles.length - 1]
            }`;
        }

        return titles.join("");
    };
    const title = formatTitle([
        {
            check:
                user?.group_permissions?.includes("add_factoryregion") ||
                user?.group_permissions?.includes("view_factoryregion"),
            title: "Regions",
        },
        {
            check:
                user?.group_permissions?.includes("add_factory") ||
                user?.group_permissions?.includes("view_factory"),
            title: user?.role === "FACTORY_ADMIN" ? "Factory" : "Factories",
        },
        {
            check:
                user?.group_permissions?.includes("change_baseusermodel") ||
                user?.group_permissions?.includes("view_baseusermodel"),
            title: "Users",
        },
    ]);
    return (
        <Layout style={{ backgroundColor: "#F4F7FF", height: "94vh" }}>
            <Row
                justify="center"
                gutter={[28, 40]}
                align="middle"
                style={{ height: "100%", paddingTop: "5%", width: "100%" }}
                onPointerEnter={() => {
                    setPreviousRoute((prev) => ({
                        defaultRoute: prev.defaultRoute,
                        activeTab: null,
                    }));
                }}>
                <Col span={4} offset={0.7}>
                    <Link to={"/home/AllCases?critical=false"}>
                        <Card
                            className={styles.DashboardCard}
                            styles={{ header: styles.titleStyle }}
                            hoverable>
                            <img
                                src="/assets/images/icon/adminDashboard/allcasesIcon.svg"
                                className={styles.iconStyle}
                            />
                            <Meta
                                title={
                                    <Title
                                        className={styles.cardTitle}
                                        level={4}>
                                        All Case Dashboard
                                    </Title>
                                }
                                className={"justify-content-center"}
                                style={{ fontSize: "24px" }}
                            />{" "}
                        </Card>
                    </Link>
                </Col>
                {user.role === "REGIONAL_ADMIN" && (
                    <Col span={4} offset={0.7}>
                        <Link to={"/home/AllCases?critical=true"}>
                            <Card
                                className={styles.DashboardCard}
                                styles={{ header: styles.titleStyle }}
                                hoverable>
                                <img
                                    src="/assets/images/icon/adminDashboard/criticalIcon.svg"
                                    className={styles.iconStyle}
                                />
                                <Meta
                                    title={
                                        <Title
                                            className={styles.cardTitle}
                                            level={4}>
                                            Critical Case Dashboard
                                        </Title>
                                    }
                                    className={"justify-content-center"}
                                    style={{ fontSize: "24px" }}
                                />
                            </Card>
                        </Link>
                    </Col>
                )}
                {["SUPER_ADMIN", "REGIONAL_ADMIN", "LEAD_SUPERVISOR"].includes(
                    user.role
                ) && (
                    <Col span={4} offset={0.7}>
                        <Link to={"/QualityInspection"}>
                            <Card
                                className={styles.DashboardCard}
                                styles={{ header: styles.titleStyle }}
                                hoverable>
                                <img
                                    src="/assets/images/icon/adminDashboard/qualityInspection.svg"
                                    className={styles.iconStyle}
                                />
                                <Meta
                                    title={
                                        <Title
                                            className={styles.cardTitle}
                                            level={4}>
                                            Quality Inspection
                                        </Title>
                                    }
                                    className={"justify-content-center"}
                                    style={{ fontSize: "24px" }}
                                />
                            </Card>
                        </Link>
                    </Col>
                )}

                {user.group_permissions.includes("view_offlinecase") && (
                    <Col span={4} offset={0.7}>
                        <Link to={"/home/OfflineCases"}>
                            <Card
                                className={styles.DashboardCard}
                                styles={{ header: styles.titleStyle }}
                                hoverable>
                                <img
                                    src="/assets/images/icon/adminDashboard/offlineCases.svg"
                                    className={styles.iconStyle}
                                />
                                <Meta
                                    title={
                                        <Title
                                            className={styles.cardTitle}
                                            level={4}>
                                            Offline Cases
                                        </Title>
                                    }
                                    className={"justify-content-center"}
                                    style={{ fontSize: "24px" }}
                                />
                            </Card>
                        </Link>
                    </Col>
                )}
                {user?.group_permissions?.includes("change_baseusermodel") ||
                user?.group_permissions?.includes("view_baseusermodel") ? (
                    <Col span={4} offset={0.7}>
                        <Link to={`/AdminTableView?key=${key}`}>
                            <Card className={styles.DashboardCard} hoverable>
                                <img
                                    src="/assets/images/icon/adminDashboard/manage.svg"
                                    className={styles.iconStyle}
                                />
                                <Meta
                                    title={
                                        <Title
                                            className={styles.cardTitle}
                                            level={4}>
                                            {title}
                                        </Title>
                                    }
                                    style={{ fontSize: "24px" }}
                                />
                            </Card>
                        </Link>
                    </Col>
                ) : null}
                {user?.user_permissions?.includes("add_broadcastmessage") ||
                user?.group_permissions?.includes("add_broadcastmessage") ? (
                    <Col span={4} offset={0.7}>
                        <Link to={"/home/BroadcastMsg"}>
                            <Card className={styles.DashboardCard} hoverable>
                                <MessageOutlined className={styles.iconStyle} />
                                <Meta
                                    title={
                                        <Title level={4}>
                                            Broadcast Message
                                        </Title>
                                    }
                                    style={{ fontSize: "24px" }}
                                />
                            </Card>
                        </Link>
                    </Col>
                ) : null}
            </Row>
            <Row
                justify="center"
                gutter={[28, 40]}
                align="middle"
                style={{ height: "100%" }}>
                {user?.user_permissions?.includes("view_analytics") && (
                    <Col span={4} offset={0.7}>
                        <Link to={"/home/reports"}>
                            <Card className={styles.DashboardCard} hoverable>
                                <img
                                    src="/assets/images/icon/adminDashboard/analyticsDashboard.svg"
                                    className={styles.iconStyle}
                                />
                                <Meta
                                    title={
                                        <Title level={4}>
                                            Analytics Dashboard
                                        </Title>
                                    }
                                    style={{ fontSize: "24px" }}
                                />
                            </Card>
                        </Link>
                    </Col>
                )}
                {user?.user_permissions?.includes("view_incentives") ? (
                    <Col span={4} offset={0.7}>
                        <Link to={"/home/incentive"}>
                            <Card className={styles.DashboardCard} hoverable>
                                <GiftOutlined className={styles.iconStyle} />
                                <Meta
                                    title={
                                        <Title level={4}>
                                            Incentive Dashboard
                                        </Title>
                                    }
                                    style={{ fontSize: "24px" }}
                                />
                            </Card>
                        </Link>
                    </Col>
                ) : null}
                {user?.user_permissions?.includes("view_holidaycalendar") ||
                user?.group_permissions?.includes("view_holidaycalendar") ? (
                    <Col span={4} offset={0.7}>
                        <Link to={"/home/HolidayCalendar"}>
                            <Card className={styles.DashboardCard} hoverable>
                                <CalendarOutlined
                                    className={styles.iconStyle}
                                />
                                <Meta
                                    title={
                                        <Title level={4}>
                                            Holiday Calender
                                        </Title>
                                    }
                                    style={{ fontSize: "24px" }}
                                />
                            </Card>
                        </Link>
                    </Col>
                ) : null}
                {user?.user_permissions?.includes("view_awarenessprogram") ||
                user?.group_permissions?.includes("view_awarenessprogram") ? (
                    <Col span={4} offset={0.7}>
                        <Link to={"/home/AwarenessProgramme"}>
                            <Card className={styles.DashboardCard} hoverable>
                                <img
                                    src="/assets/images/icon/adminDashboard/awarenessProgram.svg"
                                    className={styles.iconStyle}
                                />
                                <Meta
                                    title={
                                        <Title level={4}>
                                            Awareness Programme
                                        </Title>
                                    }
                                    style={{ fontSize: "24px" }}
                                />
                            </Card>
                        </Link>
                    </Col>
                ) : null}
                <Col span={4} offset={0.7}>
                    <Link to={"/home/PolicyDashboard"}>
                        <Card className={styles.DashboardCard} hoverable>
                            <img
                                src="/assets/images/icon/adminDashboard/policy.svg"
                                className={styles.iconStyle}
                            />
                            <Meta
                                title={
                                    <Title level={4}>Policy Dashboard</Title>
                                }
                                style={{ fontSize: "24px" }}
                            />
                        </Card>
                    </Link>
                </Col>
            </Row>
        </Layout>
    );
};

export default AdminDashboard;
