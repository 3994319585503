import UnsubscribePage from "../pages/Login/UnsubscribePage";
import { commonAxios } from "../utils/AxiosSettings";
import axios from "axios";
export async function getUserDetails(id, token) {
    if (token.access) {
        const res = await commonAxios.get(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/users/?id=${id}&operation=user`
        );
        return res;
    }
}
export async function loginUser(username, password) {
    const res = await commonAxios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/api/token/`,
        { email: username, password: password }
    );
    return res;
}

export async function getRoleToken(role_id, token) {
    const res = await commonAxios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/encode/?role_id=${role_id}`,
        {
            refresh: token.refresh,
        }
    );
    return res;
}

export async function atLoginOperations(operation, data, options) {
    const url = `${process.env.REACT_APP_BASE_URL_API}/api/accounts/userInput/?operation=${operation}`;

    const res = await commonAxios.put(url, data, options);
    return res;
}

export async function removeUserImage(userData, fd) {
    const res = await commonAxios.patch(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/user/${userData.id}`,
        fd,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );
    return res;
}

export async function getUserNameforDisplay(userid) {
    const res = await commonAxios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/users/?id=${userid}&operation=role`
    );

    return res;
}
//edit user api call
export async function patchUserDetails(submitData, userid) {
    const res = await commonAxios.patch(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/users/?operation=user&id=${userid}`,
        submitData
    );

    return res;
}

// Unsubscribe user via email api call
export async function UnsubscribeUserViaEmail(id) {
    const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/unsubscribe/${id}/`
    );
    return res;
}
