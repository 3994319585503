import { commonAxios } from "../utils/AxiosSettings";

export async function addPolicy(data) {
    const res = await commonAxios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/policies/`,
        data
    );
    return res;
}
export async function editPolicy(data) {
    const res = await commonAxios.patch(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/policies/`,
        data
    );
    return res;
}
export async function deletePolicy(id) {
    const res = await commonAxios.delete(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/policies/?id=${id}`
    );
    return res;
}
export async function getPolicies(type) {
    const res = await commonAxios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/policies/?type=${type}`
    );
    return res;
}

export async function getAllPolicies(type, operation, policyStatus) {
    const res = await commonAxios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/policies/?type=${type}&operation=${operation}&policy_status=${policyStatus}`
    );
    return res;
}

export async function getPolicyData(id) {
    const res = await commonAxios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/policies/${id}`
    );
    return res;
}
