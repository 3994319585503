import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
// Loading overlay component
export const LoadingOverlay = ({ text }) => (
    <div
        style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
        }}>
        <div style={{ textAlign: "center", color: "#fff" }}>
            <Spin
                indicator={
                    <LoadingOutlined
                        style={{ fontSize: 48, color: "#fff" }}
                        spin
                    />
                }
            />
            <p>{text}</p>
        </div>
    </div>
);
