import { Row, Col, Table, Select, Form, DatePicker, Modal } from "antd";
import React from "react";
import { useState, useEffect } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import {
    AwarenessProgrammeData,
    AwarenessProgrammeDataByID,
} from "../../../../Adapters/AwarenessProgrammeCalls";
import AwarenessModal from "../AwarenessModal";
import { Input, Button } from "antd";
import DateTimeFormatter from "../../../../components/Utils/DateTimeFormatter";
import { useRecoilState } from "recoil";
import { factoryState } from "../../../../RecoilState/FactoryState";
const AwarenessTable = () => {
    const { Option } = Select;
    const duarationfilter = {
        Monthly: "month",
        Quartly: "quarter",
        "Half Yearly": "half",
        Yearly: "year",
    };

    const currentMonth = dayjs().month();
    const [duration, setDuration] = useState("month");
    const [dateString, setdateString] = useState("");
    const [halfYear, setHalfYear] = useState("");
    const [tableData, setTableData] = useState("");
    const [defaultHalfYear, setDefaultHalfYear] = useState("");
    const [statusData, setSttausData] = useState("");
    const [awerenessModalData, setAwarenessModalData] = useState();
    const [open, setOpen] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [FactorySelected, setFactorySelected] = useRecoilState(factoryState);

    const searchDropdownVisible = (visible) => {
        if (visible) {
            setSelectedKeys([]);
        } else {
            setSelectedKeys([]);
        }
    };
    const programmeCount = [
        {
            programmeCount: statusData?.Required,
            text: "Required Programme",
            color: " #868CFF",
        },
        {
            programmeCount: statusData?.Conducted,
            text: "Conducted Programme",
            color: "#52C41A",
        },
        {
            programmeCount: statusData?.Pending,
            text: "Pending/Missed Programme",
            color: "#FF0000",
        },
    ];
    const durationHandler = (value) => {
        setDuration(value);
    };
    const gettableData = async () => {
        try {
            //api call to get  AwarenessProgramme table data
            const res = await AwarenessProgrammeData(
                duration,
                dateString,
                FactorySelected.id
            );
            setTableData(res.data?.message_body.Programs);
            setSttausData(res.data?.message_body.Status);
        } catch (error) {}
    };
    const rowClickHandler = async (record) => {
        //api call to get individual AwarenessProgramme
        showModal();
        const res = await AwarenessProgrammeDataByID(record.programNumber);
        setAwarenessModalData(res?.data.message_body);
    };
    const showModal = () => {
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };

    const onChange = (_, dateString) => {
        setdateString(dateString);
    };

    const onHalfYearChange = (_, dateString) => {
        // when user select halfyear value
        if (halfYear !== "") {
            const halfYearString = `${dateString}-${halfYear}`;
            setdateString(halfYearString);
        }
        //when the defaultHalfYear is present
        else if (defaultHalfYear !== "") {
            const halfYearString = `${dateString}-${defaultHalfYear}`;
            setdateString(halfYearString);
        }
    };

    const halfYearlyHandler = (value) => {
        setHalfYear(value);
    };

    useEffect(() => {
        if (dateString !== "") {
            const dateStringStr = dateString.toString();
            const newHalfYearString = dateStringStr.replace(/H\d/, halfYear);
            setdateString(newHalfYearString);
        }
    }, [halfYear]);

    useEffect(() => {
        //function call to get table data
        gettableData();
    }, [dateString, halfYear, FactorySelected]);

    useEffect(() => {
        //clearing the states on change of duration dropdown
        setHalfYear("");
        setdateString("");
        //setting all the defaultvalue based on current month, year, quarter, and half year
        let defaultvalue;
        switch (duration) {
            case "month":
                defaultvalue = dayjs().format("YYYY-MM");
                break;
            case "quarter":
                defaultvalue = dayjs().format("YYYY-[Q]Q");
                break;
            case "year":
                defaultvalue = dayjs().year();
                break;
            case "half":
                const defaultHalfYear = dayjs().month() < 6 ? "H1" : "H2";
                defaultvalue = `${dayjs().year()}-${defaultHalfYear}`;
                break;
            default:
                break;
        }
        setdateString(defaultvalue);
    }, [duration]);

    useEffect(() => {
        // Determine the default half-year value based on the current month
        if (currentMonth < 6) {
            setDefaultHalfYear("H1"); // First half of the year
        } else {
            setDefaultHalfYear("H2"); // Second half of the year
        }
    }, []);

    const filterData = (data, formatter) =>
        _.uniqBy(data, formatter).map((item) => ({
            text: formatter(item),
            value: formatter(item),
        }));

    const searchDropdown = ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }) => {
        return (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder="Search"
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={confirm}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Button
                    type="primary"
                    onClick={confirm}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    Filter
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        confirm();
                    }}
                    size="small"
                    style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        );
    };

    const columns = [
        {
            id: "1",
            title: "Awareness Number",
            dataIndex: "programNumber",
            key: "programNumber",
            filterMode: "tree",
            filterSearch: true,
            sorter: (a, b) => a.programNumber - b.programNumber,
            filters: filterData(tableData, (item) => item.programNumber),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const programNumber = record.programNumber
                    .toString()
                    .toLowerCase()
                    .trim();
                return programNumber.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
            width: "13%",
            render: (text, record) => (
                <span
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                    {text}
                    {record.Type === "Inache Awareness Program" && (
                        <img
                            src="/assets/images/AwarenessProgramme/check-circle.svg"
                            alt="Tick"
                            style={{ width: "20px", borderRadius: "50%" }}
                        />
                    )}
                </span>
            ),
        },
        {
            id: "2",
            title: "Programme Name",
            dataIndex: "programName",
            key: "programName",
            filterMode: "tree",
            filterSearch: true,
            filters: filterData(tableData, (item) => item.programName),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const programName = record.programName
                    .toString()
                    .toLowerCase()
                    .trim();
                return programName.indexOf(searchValue) !== -1;
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
            width: "15%",
        },
        {
            id: "3",
            title: "Conducted On",
            dataIndex: "Date",
            key: "Date",
            filterMode: "tree",
            sorter: (a, b) =>
                new Date(a.Date).getTime() - new Date(b.Date).getTime(),
            width: "15%",
            render: (_, record, index) => {
                return (
                    <DateTimeFormatter
                        dateTimeString={record.Date}
                        requiredField="date"
                    />
                );
            },
        },
        {
            id: "4",
            title: "Uploaded by",
            dataIndex: "uploadedBy",
            key: "uploadedBy",
            filterMode: "menu",
            filters: filterData(tableData, (item) => item.uploadedBy),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const uploadedBy = record.uploadedBy
                    .toString()
                    .toLowerCase()
                    .trim();
                return uploadedBy.indexOf(searchValue) !== -1;
            },

            width: "15%",
        },
        {
            id: "3",
            title: "Uploaded On",
            dataIndex: "uploadedAt",
            key: "uploadedAt",
            filterMode: "menu",
            sorter: (a, b) =>
                new Date(a.uploadedAt).getTime() -
                new Date(b.uploadedAt).getTime(),
            width: "15%",
            render: (_, record, index) => {
                return <DateTimeFormatter dateTimeString={record.uploadedAt} />;
            },
        },
        {
            id: "5",
            title: "No. of attendees",
            dataIndex: "participants",
            key: "participants",
            sorter: (a, b) => a.participants - b.participants,
            width: "15%",
        },
    ];

    return (
        <>
            <Row
                style={{
                    backgroundColor: "white",
                    margin: "0 2rem 2rem",
                    padding: "1rem",
                    borderRadius: "12px",
                    minHeight: "70vh",
                }}>
                <Col span={24}>
                    <Row
                        justify="space-between"
                        style={{
                            borderBottom: "1px solid #BEBEBE",
                            padding: "0 0 1rem 0",
                            marginBottom: "1rem",
                        }}>
                        <Col>
                            {duration === "half" && (
                                <>
                                    <Select
                                        className="custom-select"
                                        placeholder="Select Half yearly value"
                                        defaultValue={defaultHalfYear}
                                        onChange={halfYearlyHandler}>
                                        <Option value="H1">
                                            First Half Year
                                        </Option>
                                        <Option value="H2">
                                            Second Half Year
                                        </Option>
                                    </Select>
                                    <DatePicker
                                        disabledDate={(current) =>
                                            current &&
                                            current.isAfter(
                                                dayjs().endOf("day")
                                            )
                                        }
                                        style={{
                                            padding: "8px 11px 8px",
                                            boxShadow:
                                                "0px 2px 4px rgba(90, 91, 106, 0.24), 0px 1px 2px rgba(58, 58, 68, 0.24)",
                                        }}
                                        onChange={onHalfYearChange}
                                        picker="year"
                                        defaultValue={dayjs().startOf("year")}
                                    />
                                </>
                            )}
                            {duration === "year" && (
                                <DatePicker
                                    disabledDate={(current) =>
                                        current &&
                                        current.isAfter(dayjs().endOf("day"))
                                    }
                                    style={{
                                        padding: "8px 11px 8px",
                                        boxShadow:
                                            "0px 2px 4px rgba(90, 91, 106, 0.24), 0px 1px 2px rgba(58, 58, 68, 0.24)",
                                    }}
                                    onChange={onChange}
                                    picker={duration}
                                    defaultValue={dayjs().startOf("year")}
                                />
                            )}
                            {duration === "month" && (
                                <DatePicker
                                    disabledDate={(current) =>
                                        current &&
                                        current.isAfter(dayjs().endOf("day"))
                                    }
                                    format="MM-YYYY"
                                    style={{
                                        padding: "8px 11px 8px",
                                        boxShadow:
                                            "0px 2px 4px rgba(90, 91, 106, 0.24), 0px 1px 2px rgba(58, 58, 68, 0.24)",
                                    }}
                                    onChange={onChange}
                                    picker={duration}
                                    defaultValue={dayjs().startOf("month")}
                                />
                            )}
                            {duration === "quarter" && (
                                <DatePicker
                                    disabledDate={(current) =>
                                        current &&
                                        current.isAfter(dayjs().endOf("day"))
                                    }
                                    style={{
                                        padding: "8px 11px 8px",
                                        boxShadow:
                                            "0px 2px 4px rgba(90, 91, 106, 0.24), 0px 1px 2px rgba(58, 58, 68, 0.24)",
                                    }}
                                    onChange={onChange}
                                    picker={duration}
                                    defaultValue={dayjs().startOf("quarter")}
                                />
                            )}
                        </Col>
                        <Col>
                            <Select
                                defaultValue="Monthly"
                                onChange={durationHandler}>
                                {Object.entries(duarationfilter).map((item) => {
                                    return (
                                        <Select.Option
                                            key={item[0]}
                                            value={item[1]}>
                                            {item[0]}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Col>
                    </Row>
                    <Row justify="space-evenly">
                        {programmeCount.map((item) => {
                            return (
                                <Col span={8} key={item.text}>
                                    <Row
                                        style={{
                                            backgroundColor: "#E7E8FF",
                                            padding: ".7rem",
                                            margin: "1rem",
                                            borderRadius: "16px",
                                            border: `1px solid ${item.color}`,
                                            color: "black",
                                            alignItems: "center",
                                        }}>
                                        <Col
                                            style={{
                                                margin: " 0 1rem",
                                                fontSize: "32px",
                                                fontWeight: "bold",
                                            }}>
                                            {item.programmeCount}
                                        </Col>
                                        <Col
                                            style={{
                                                fontSize: "16px",
                                            }}>
                                            {item.text}
                                        </Col>
                                    </Row>
                                </Col>
                            );
                        })}
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                dataSource={tableData}
                                // onChange={onChange}
                                // pagination={pagination}
                                rowKey="programNumber"
                                scroll={{ y: "380px" }}
                                onRow={(record, index) => ({
                                    onClick: () => rowClickHandler(record),
                                    style: {
                                        background: record.Breached
                                            ? "#FFECEC"
                                            : null,
                                    },
                                })}
                            />
                        </Col>
                    </Row>
                </Col>
                <Modal
                    width={900}
                    centered
                    title={`${awerenessModalData?.Type} Details`}
                    open={open}
                    onCancel={handleCancel}
                    footer={false}>
                    <AwarenessModal awerenessModalData={awerenessModalData} />
                </Modal>
            </Row>
        </>
    );
};

export default AwarenessTable;
