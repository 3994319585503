import { commonAxios } from "../utils/AxiosSettings";

export async function getCases(critical, activeTab, factoryIDs, company) {
    if (activeTab) {
        // check if IDs are received as an array or an integer
        const factories = Array.isArray(factoryIDs)
            ? factoryIDs.join(",")
            : factoryIDs;

        const queryUrl = new URL(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/company/${company}/cases/`
        );
        //adding param based on type of data need to show
        queryUrl.searchParams.append("case_type", activeTab);
        queryUrl.searchParams.append("critical", critical);
        queryUrl.searchParams.append("factory", factories);

        const res = await commonAxios.get(queryUrl);
        return res;
    }
    return null;
}
