import { commonAxios } from "../utils/AxiosSettings";
export async function getQCIntroViewed() {
    let res = await commonAxios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/qcintro/`
    );
    return res.data;
}

export async function updateQCIntroViewed(introFlow) {
    let res = await commonAxios.patch(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/qcintro/`,
        {
            introFlow: introFlow,
        }
    );
    return res.data;
}
