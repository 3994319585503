import { Button, Card, Layout, Spin, Select } from "antd";
import React from "react";
import CollapsableMenu from "../../components/CollapsableMenu";
import { Row, Col } from "antd";
import { useState, useEffect } from "react";
import AwarenessProgrammeUpload from "./AwarenessProgrammeUpload/index.jsx";
import AwarenessTable from "./AwarenessProgrammeTable/AwarenessTable/index";
import ButtonCard from "../../components/Home/TableContainer/ButtonContainer/ButtonCard";
import styles from "./Awareness.module.css";
import { userState } from "../../RecoilState/userState";
import { factoryState } from "../../RecoilState/FactoryState";
import { tokenState } from "../../RecoilState/tokenState";
import { FactoryData } from "../../RecoilState/FactoryData";
import { useRecoilState } from "recoil";
import { getAllFactoriesOfCompany } from "../../Adapters/FactoryAndCompanyCalls";

const AwarnessProgramme = () => {
    const [uploadAwarenessFlag, setUploadAwarenessFlag] = useState(false);
    const [factorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [factoryDataList] = useRecoilState(FactoryData);
    const [user] = useRecoilState(userState);
    const [token] = useRecoilState(tokenState);

    const handleFactoryChange = (value) => {
        const selectedFactory = factoryDataList.find(
            (f) => `${f.Code} - ${f.Location}` === value
        );
        setFactorySelected(selectedFactory);
    };

    function setUploadFalse() {
        setUploadAwarenessFlag(false);
    }

    return (
        <Layout>
            <CollapsableMenu></CollapsableMenu>
            <div className={styles.containerCard}>
                <Row
                    justify="space-between"
                    style={{
                        margin: "1rem 0 0 0",
                        display: "flex",
                        alignItems: "center",
                    }}>
                    <Col flex="60%">
                        <h1
                            id="AwarenessProgramme"
                            style={{
                                textAlign: "start",
                                padding: "1rem 0 0 2rem",
                            }}>
                            Awareness Programme
                        </h1>
                    </Col>

                    <Col flex="15%">
                        {(user.role === "SUPER_ADMIN" ||
                            user.role === "LEAD_SUPERVISOR" ||
                            user.role === "REGIONAL_ADMIN") && (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginLeft: "1rem",
                                }}>
                                {factoryDataList ? (
                                    <Select
                                        className={styles.dropdown}
                                        style={{ width: "100%" }}
                                        value={
                                            factorySelected
                                                ? `${factorySelected.Code} - ${factorySelected.Location}`
                                                : factoryDataList.length > 0
                                                ? `${factoryDataList[0].Code} - ${factoryDataList[0].Location}`
                                                : undefined
                                        }
                                        onChange={handleFactoryChange}
                                        options={factoryDataList.map(
                                            (factory) => ({
                                                value: `${factory.Code} - ${factory.Location}`,
                                                label: `${factory.Code} - ${factory.Location}`,
                                            })
                                        )}
                                        placeholder="Select Factory"
                                    />
                                ) : (
                                    <Spin />
                                )}
                            </div>
                        )}
                    </Col>
                    <Col flex="15%">
                        {!uploadAwarenessFlag &&
                            (user?.user_permissions?.includes(
                                "add_awarenessprogram"
                            ) ||
                                user?.group_permissions?.includes(
                                    "add_awarenessprogram"
                                )) && (
                                <Col>
                                    <div
                                        style={{
                                            float: "right",
                                            marginRight: "1rem",
                                        }}
                                        onClick={() => {
                                            setUploadAwarenessFlag(true);
                                        }}>
                                        <ButtonCard
                                            key="Upload Awareness Programme"
                                            text="Upload Awareness Programme"
                                            colorLogo="purple"
                                            icon="/assets/images/AwarenessProgramme/upload.svg"
                                        />
                                    </div>
                                </Col>
                            )}
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {uploadAwarenessFlag === false ? (
                            <AwarenessTable />
                        ) : (
                            <AwarenessProgrammeUpload
                                setUploadFalse={setUploadFalse}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        </Layout>
    );
};

export default AwarnessProgramme;
