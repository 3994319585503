import { commonAxios } from "../utils/AxiosSettings";
export async function getDivisionDetails(company) {
    const res = await commonAxios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/division/?Company=${company}`
    );

    return res;
}

export async function addDivision(name) {
    const res = await commonAxios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/division/`,
        { Name: name }
    );
    return res;
}
export async function editDivision(id, name) {
    const res = await commonAxios.patch(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/division/?id=${id}`,
        { Name: name }
    );
    return res;
}
