import { Menu } from "antd";
import React from "react";
import styles from "../Header.module.css";
import AccountModal from "./AccountModal";
import useLogout from "../../../hooks/useLogout.js";

const ProfileModal = React.lazy(() => import("./ProfileModal.jsx"));

export default function UserProfileMenu(props) {
    const [openAccount, setOpenAccount] = React.useState(false);
    const { handleLogout } = useLogout();

    async function handleUserLogout() {
        if (localStorage.getItem("flag")) {
            localStorage.setItem("flag", false);
        }
        await handleLogout("Proper Logout");
    }

    const [modalVisible, setModalVisible] = React.useState(false);

    const handleOpenModal = () => {
        setModalVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    const menuItems = [
        {
            key: "profile",
            label: "My profile",
            onClick: handleOpenModal,
        },
        {
            type: "divider",
        },
        {
            key: "logout",
            label: "Log out",
            onClick: handleUserLogout,
        },
    ];

    return (
        <>
            <ProfileModal visible={modalVisible} onCancel={handleCloseModal} />
            <AccountModal
                showAccount={openAccount}
                setshowAccount={setOpenAccount}
            />
            {props.showDropper && (
                <div className={styles.dropMenuUserProfile}>
                    <Menu
                        items={menuItems}
                        style={{
                            width: "180px",
                            boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
                            borderRadius: "4px",
                        }}
                    />
                </div>
            )}
        </>
    );
}
