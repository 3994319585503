import React, { useState, useEffect } from "react";
import "antd/dist/reset.css";
import {
    Row,
    Col,
    DatePicker,
    Modal,
    Select,
    Calendar,
    message,
    Button,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import AddEditModal from "./AddEditModal";
import { getAllHolidays } from "../../../Adapters/HolidayCalendar";
import { userState } from "../../../RecoilState/userState";
import { tokenState } from "../../../RecoilState/tokenState";
import { useRecoilState } from "recoil";
import { selectedMonthYearRecoil } from "../../../RecoilState/HolidayCalender/selectedMonthYearRecoil";
// import styles from "../Holiday.module.css";
import styles from "./ViewCalendar.module.css";
import { deleteHoliday } from "../../../Adapters/HolidayCalendar";
import DateTimeFormatter from "../../../components/Utils/DateTimeFormatter";

const ViewCalendar = ({ FactorySelected }) => {
    dayjs.extend(isBetween);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(isSameOrAfter);

    const [modalVisible, setModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedMonthYear, setSelectedMonthYear] = useRecoilState(
        selectedMonthYearRecoil
    );
    const [mode, setMode] = useState("month");
    const [user, setUser] = useRecoilState(userState);
    const [token, setToken] = useRecoilState(tokenState);
    const [dataUpdateFlag, setDataUpdateFlag] = useState(false);
    const [holidayData, setHolidayData] = useState([]);
    const [formattedHolidayData, setFormattedHolidayData] = useState([]);
    const [dateString, setdateString] = useState(dayjs().format("YYYY-MM-DD"));
    const [showDatePicker, setShowDatePicker] = useState(false);

    useEffect(() => {
        setFormattedHolidayData(fixHolidayData());
        return () => {};
    }, [holidayData]);

    const fixHolidayData = () => {
        var formattedData = {};
        holidayData.forEach(function (holiday) {
            var startDate = dayjs(holiday.startDate);
            var endDate = dayjs(holiday.endDate);
            var diffInDays = endDate.diff(startDate, "day");

            for (var i = 0; i <= diffInDays; i++) {
                var currentDate = startDate.add(i, "day");
                var year = currentDate.year();
                var month = currentDate.month();
                var day = currentDate.date();

                var holidayEntry = {
                    eventName: holiday.eventName,
                    startDate: holiday.startDate,
                    endDate: holiday.endDate,
                    id: holiday.id,
                    type: "success",
                    content: holiday.eventName,
                    holidayState: "middle",
                    levels: 0,
                    flag: false, // Initialize levels to 0
                };

                if (i === 0 && diffInDays === 0) {
                    holidayEntry.holidayState = "start-end";
                }
                if (i === 0 && diffInDays != 0) {
                    holidayEntry.holidayState = "start";
                } else if (i === diffInDays && diffInDays != 0) {
                    holidayEntry.holidayState = "end";
                }

                if (!formattedData[year]) {
                    formattedData[year] = {};
                }

                if (!formattedData[year][month]) {
                    formattedData[year][month] = {};
                }

                if (!formattedData[year][month][day]) {
                    formattedData[year][month][day] = [];
                }

                formattedData[year][month][day].push(holidayEntry);
                // Update levels for subsequent events on the same date
                var eventsOnDate = formattedData[year][month][day];
                var numEvents = eventsOnDate.length;
                if (numEvents > 1) {
                    holidayEntry.levels = numEvents - 1;
                }
                if (
                    (holidayEntry.holidayState === "middle" ||
                        holidayEntry.holidayState === "end") &&
                    formattedData[year][month][day - 1]?.some(
                        (entry) => entry.id === holidayEntry.id
                    )
                ) {
                    // Get the matching entry
                    var matchingEntry = formattedData[year][month][
                        day - 1
                    ].find((entry) => entry.id === holidayEntry.id);

                    // Assign the same level to the current entry
                    holidayEntry.levels = matchingEntry.levels;
                    holidayEntry.flag = true;
                }
            }
        });
        return formattedData;
    };

    const dateCellRender = (date) => {
        var day = date.date();
        var month = date.month();
        var year = date.year();
        var events = [];

        if (
            formattedHolidayData &&
            formattedHolidayData[year] &&
            formattedHolidayData[year][month] &&
            formattedHolidayData[year][month][day]
        ) {
            events = formattedHolidayData[year][month][day];
        }

        if (events && events.length > 0) {
            return (
                <div
                    onClick={() =>
                        // deleteModalVisible
                        //     ? setModalVisible(false)
                        //     : setModalVisible(true)
                        date >= new Date() && setModalVisible(true)
                    }>
                    {events.map((event, index) => (
                        <div
                            key={event.id}
                            style={{
                                marginTop: `${event.levels * 10}px`,
                            }}>
                            {event.holidayState === "start" && (
                                <div
                                    onClick={() => handleViewEvent(event)}
                                    style={{
                                        backgroundColor: "#868CFF1A",
                                        borderTopLeftRadius: "20px",
                                        borderBottomLeftRadius: "20px",
                                        borderTopRightRadius: "0px",
                                        borderBottomRightRadius: "0px",
                                        paddingLeft: "10px",
                                        border: "1px solid #4318FF",
                                        borderRight: "0",
                                    }}>
                                    {event.content}
                                </div>
                            )}
                            {event.holidayState === "middle" && (
                                <div
                                    onClick={() => handleViewEvent(event)}
                                    style={{
                                        borderTop: "1px solid #4318FF",
                                        borderBottom: "1px solid #4318FF",
                                        backgroundColor: "#868CFF1A",
                                        marginTop:
                                            event.flag &&
                                            event.levels > 0 &&
                                            events.length === 1
                                                ? `${event.levels * 32}px`
                                                : "0px",
                                    }}>
                                    &nbsp;
                                </div>
                            )}
                            {event.holidayState === "end" && (
                                <div
                                    onClick={() => handleViewEvent(event)}
                                    style={{
                                        border: "1px solid #4318FF",
                                        borderLeft: "0",
                                        backgroundColor: "#868CFF1A",
                                        borderTopLeftRadius: "0px",
                                        borderBottomLeftRadius: "0px",
                                        borderTopRightRadius: "20px",
                                        borderBottomRightRadius: "20px",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginTop:
                                            event.flag &&
                                            event.levels > 0 &&
                                            events.length === 1
                                                ? `${event.levels * 32}px`
                                                : "0px",
                                    }}>
                                    <div style={{ marginRight: "10px" }}>
                                        {user?.user_permissions?.includes(
                                            "change_holidaycalendar"
                                        ) ||
                                        user?.group_permissions?.includes(
                                            "change_holidaycalendar"
                                        ) ? (
                                            <>
                                                <Button
                                                    icon={
                                                        <EditOutlined
                                                            style={{
                                                                fontSize:
                                                                    "14px",
                                                                width: "20px",
                                                                height: "16px",
                                                            }}
                                                        />
                                                    }
                                                    type="text"
                                                    onClick={() =>
                                                        handleEditEvent(event)
                                                    }></Button>
                                                <Button
                                                    icon={
                                                        <DeleteOutlined
                                                            style={{
                                                                fontSize:
                                                                    "14px",
                                                                width: "20px",
                                                                height: "16px",
                                                            }}
                                                        />
                                                    }
                                                    type="text"
                                                    onClick={() =>
                                                        deleteModalhandle(event)
                                                    }></Button>{" "}
                                            </>
                                        ) : (
                                            <div
                                                style={{
                                                    fontSize: "14px",
                                                    width: "20px",
                                                    height: "22px",
                                                }}></div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {event.holidayState === "start-end" && (
                                <div
                                    onClick={() => handleViewEvent(event)}
                                    style={{
                                        border: "1px solid #4318FF",

                                        backgroundColor: "#868CFF1A",
                                        borderTopLeftRadius: "20px",
                                        borderBottomLeftRadius: "20px",
                                        borderTopRightRadius: "20px",
                                        borderBottomRightRadius: "20px",
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}>
                                    <div style={{ flexGrow: 1 }}>
                                        {event.content}
                                    </div>
                                    <div style={{ marginLeft: "20px" }}>
                                        {(user?.user_permissions?.includes(
                                            "change_holidaycalendar"
                                        ) ||
                                            user?.group_permissions?.includes(
                                                "change_holidaycalendar"
                                            )) && (
                                            <>
                                                <Button
                                                    icon={
                                                        <EditOutlined
                                                            style={{
                                                                fontSize:
                                                                    "14px",
                                                                width: "20px",
                                                                height: "16px",
                                                            }}
                                                        />
                                                    }
                                                    type="text"
                                                    onClick={() =>
                                                        handleEditEvent(event)
                                                    }></Button>
                                                <Button
                                                    icon={
                                                        <DeleteOutlined
                                                            style={{
                                                                fontSize:
                                                                    "14px",
                                                                width: "20px",
                                                                height: "16px",
                                                            }}
                                                        />
                                                    }
                                                    type="text"
                                                    onClick={() =>
                                                        deleteModalhandle(event)
                                                    }></Button>{" "}
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    };

    useEffect(() => {
        setModalVisible(false);
    }, [deleteModalVisible]);

    const monthCellRender = (date, locale) => {
        const month = date.month();
        const year = date.year();
        const events = [];

        if (
            formattedHolidayData &&
            formattedHolidayData[year] &&
            formattedHolidayData[year][month]
        ) {
            Object.values(formattedHolidayData[year][month]).forEach(
                (dayEvents) => {
                    events.push(...dayEvents);
                }
            );
        }

        if (events.length > 0) {
            return (
                <div>
                    {events?.map((event, index) => {
                        const start = dayjs(event?.startDate).startOf("month");
                        const end = dayjs(event?.endDate).endOf("month");
                        const currentDate = dayjs().startOf("month");

                        if (currentDate.isSameOrBefore(end)) {
                            return (
                                <div
                                    key={event.id}
                                    style={{
                                        marginTop: "10px",
                                    }}>
                                    {(event.holidayState === "start" ||
                                        event.holidayState === "start-end") && (
                                        <div
                                            onClick={() =>
                                                handleViewEvent(event)
                                            }
                                            style={{
                                                border: "1px solid #4318FF",
                                                backgroundColor: "#868CFF1A",
                                                borderTopLeftRadius: "20px",
                                                borderBottomLeftRadius: "20px",
                                                borderTopRightRadius: "20px",
                                                borderBottomRightRadius: "20px",
                                                paddingLeft: "10px",
                                                paddingRight: "10px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}>
                                            <div style={{ flexGrow: 1 }}>
                                                {event.content}
                                            </div>
                                            {user?.user_permissions?.includes(
                                                "change_holidaycalendar"
                                            ) ||
                                            user?.group_permissions?.includes(
                                                "change_holidaycalendar"
                                            ) ? (
                                                <>
                                                    <Button
                                                        icon={
                                                            <EditOutlined
                                                                style={{
                                                                    fontSize:
                                                                        "14px",
                                                                    width: "20px",
                                                                    height: "16px",
                                                                }}
                                                            />
                                                        }
                                                        type="text"
                                                        onClick={() =>
                                                            handleEditEvent(
                                                                event
                                                            )
                                                        }></Button>
                                                    <Button
                                                        icon={
                                                            <DeleteOutlined
                                                                style={{
                                                                    fontSize:
                                                                        "14px",
                                                                    width: "20px",
                                                                    height: "16px",
                                                                }}
                                                            />
                                                        }
                                                        type="text"
                                                        onClick={() =>
                                                            deleteModalhandle(
                                                                event
                                                            )
                                                        }></Button>{" "}
                                                </>
                                            ) : (
                                                <div
                                                    style={{
                                                        fontSize: "14px",
                                                        width: "20px",
                                                        height: "16px",
                                                    }}>
                                                    {" "}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        }

                        return null;
                    })}
                </div>
            );
        }

        return null;
    };

    // change wrapper function so dateString state receives value in proper format
    const handleWrapperClick = (date, event) => {
        setSelectedDate(date);

        const updatedDate = dayjs(date).format("YYYY-MM-DD");

        setdateString(updatedDate);

        if (!event?.id) {
            handleDateClick(date, event);
        }
    };

    const handleEditEvent = (event) => {
        if (
            user?.user_permissions.some(
                (val) =>
                    val === "add_holidaycalendar" ||
                    val === "change_holidaycalendar"
            ) ||
            user?.group_permissions.some(
                (val) =>
                    val === "add_holidaycalendar" ||
                    val === "change_holidaycalendar"
            )
        )
            setSelectedEvent(event);
        setModalVisible(true);
    };

    const handleViewEvent = (event) => {
        if (
            user?.user_permissions.some(
                (val) =>
                    val !== "add_holidaycalendar" ||
                    val !== "change_holidaycalendar"
            ) ||
            user?.group_permissions.some(
                (val) =>
                    val !== "add_holidaycalendar" ||
                    val !== "change_holidaycalendar"
            )
        )
            setSelectedEvent(event);
        setModalVisible(true);
    };

    const handleDateClick = (date, event) => {
        const isHeaderDropdown =
            event.target.classList.contains(
                "ant-picker-calendar-date-content"
            ) ||
            event.target.classList.contains("ant-picker-calendar-date-value");

        setSelectedDate(date);

        if (isHeaderDropdown) {
            const selectedEvent = holidayData?.find((holiday) => {
                const start = dayjs(holiday?.startDate).startOf("day");
                const end = dayjs(holiday?.endDate).endOf("day");
                return dayjs(date).isBetween(start, end, null, "[]");
            });
            const currentDate = dayjs().startOf("day");
            const clickedDate = dayjs(date).startOf("day");

            if (
                user?.user_permissions.some(
                    (val) =>
                        val === "add_holidaycalendar" ||
                        val === "change_holidaycalendar"
                ) ||
                (user?.group_permissions.some(
                    (val) =>
                        val === "add_holidaycalendar" ||
                        val === "change_holidaycalendar"
                ) &&
                    clickedDate?.isSameOrAfter(currentDate)) // Only allow opening the modal for the current day and future days
            ) {
                setModalVisible(true);
            }
        }
    };

    const deleteModalhandle = (event) => {
        setDeleteModalVisible(true);
        setSelectedEvent(event);
    };

    const handleDeleteEvent = async () => {
        try {
            const res = await deleteHoliday(selectedEvent.id);

            // Handle success or any other actions after deleting the event
            if (res.status === 200) {
                message.success("The Holiday has been Deleted Successfully");
                fetchHolidays(
                    selectedMonthYear,
                    mode,
                    setHolidayData,
                    FactorySelected
                );
                setDeleteModalVisible(false);
                setSelectedEvent(null);
            } else {
                message.error(res.data.errorMessage);
            }
        } catch (error) {
            console.error("Error deleting event:", error);
            if (error.response && error.response.data) {
                setDeleteModalVisible(false);
                message.error(error.response.data.errorMessage);
            } else {
                message.error("An error occurred while deleting the event.");
                setDeleteModalVisible(false);
            }
        }
    };

    // Receives the selected value mode and sets selectedMonthYear based on month or year
    const handleSelectChange = (selectedMode) => {
        setMode(selectedMode);
        if (selectedMode === "month") {
            setSelectedMonthYear(dayjs(dateString).format("YYYY-MM"));
        } else {
            setSelectedMonthYear(dayjs(dateString).format("YYYY"));
        }
    };

    // changes the current displayed month to the previous month
    const prevMonth = () => {
        const newDate =
            mode === "month"
                ? dayjs(dateString).subtract(1, "months")
                : dayjs(dateString).subtract(1, "year");
        const updatedDate =
            mode === "month"
                ? newDate.format("YYYY-MM")
                : newDate.format("YYYY");
        setdateString(updatedDate);
        setSelectedMonthYear(updatedDate);
    };

    // changes the current displayed month to the next month
    const nextMonth = () => {
        const newDate =
            mode === "month"
                ? dayjs(dateString).add(1, "months")
                : dayjs(dateString).add(1, "year");
        const updatedDate =
            mode === "month"
                ? newDate.format("YYYY-MM")
                : newDate.format("YYYY");
        setdateString(updatedDate);
        setSelectedMonthYear(updatedDate);
    };

    const handleDateChange = (date) => {
        if (date) {
            const updatedDate =
                mode === "month" ? date.format("YYYY-MM") : date.format("YYYY");
            setdateString(updatedDate);
            setSelectedMonthYear(updatedDate);
            setShowDatePicker(false);
        }
    };

    // a custom header for the calendar
    const customHeaderRender = () => (
        <div className={styles.customHeader}>
            <div className={styles.datePickerPanel}>
                <div className={styles.datePanel}>
                    <img
                        src="../assets/images/MonthFilter/PrevArrow.svg"
                        alt="prev"
                        onClick={prevMonth}
                    />
                    <span
                        className={styles.monthYearPanel}
                        onClick={() => setShowDatePicker(true)}>
                        {mode === "month" && dayjs(dateString).format("MMMM")}{" "}
                        {dayjs(dateString).format("YYYY")}
                    </span>
                    <img
                        src="../assets/images/MonthFilter/NextArrow.svg"
                        alt="next"
                        onClick={nextMonth}
                    />
                </div>
                {showDatePicker && (
                    <div className="hidden-date-picker">
                        <DatePicker
                            open={showDatePicker}
                            onChange={handleDateChange}
                            picker={mode === "month" ? "day" : "month"}
                            value={dayjs(dateString)}
                            onOpenChange={(open) => setShowDatePicker(open)}
                        />
                    </div>
                )}
            </div>
            <Select
                defaultValue="Monthly"
                onChange={handleSelectChange}
                options={[
                    { value: "month", label: "Monthly" },
                    { value: "year", label: "Yearly" },
                ]}
            />
        </div>
    );

    const fetchHolidays = async (
        selectedMonthYear,
        mode,
        setHolidayData,
        FactorySelected
    ) => {
        if (token && FactorySelected) {
            try {
                const res = await getAllHolidays(
                    selectedMonthYear,
                    mode,
                    FactorySelected
                ).catch((error) => {
                    message.error(error?.response?.data?.errorMessage);
                });
                setHolidayData(res.data.message_body.Holidays);
            } catch (error) {
                console.log("Error fetching holidays:", error);
            }
        }
    };

    useEffect(() => {
        fetchHolidays(selectedMonthYear, mode, setHolidayData, FactorySelected);
    }, [selectedMonthYear, dataUpdateFlag, FactorySelected]);

    return (
        <>
            <Row
                style={{
                    width: "96%",
                    backgroundColor: "white",
                    margin: "0 2rem 2rem",
                    padding: "1rem",
                    borderRadius: "12px",
                    minHeight: "70vh",
                }}>
                <Col span={24}>
                    <Row>
                        <Col span={24}>
                            <Calendar
                                onSelect={(date) =>
                                    handleWrapperClick(date, window.event)
                                }
                                value={dayjs(dateString)}
                                dateCellRender={dateCellRender}
                                headerRender={customHeaderRender}
                                monthCellRender={monthCellRender}
                                mode={mode}
                            />
                        </Col>
                    </Row>
                </Col>
                <Modal
                    destroyOnClose={true}
                    title={"Holiday details"}
                    open={modalVisible}
                    onCancel={() => {
                        setModalVisible(false);
                        setSelectedEvent(null);
                    }}
                    footer={null}>
                    <AddEditModal
                        selectedEvent={selectedEvent}
                        selectedDate={selectedDate}
                        setModalVisible={setModalVisible}
                        setDataUpdateFlag={setDataUpdateFlag}
                        dataUpdateFlag={dataUpdateFlag}
                        factory={FactorySelected}
                    />
                </Modal>
                <Modal
                    destroyOnClose={true}
                    title={"Please Confirm"}
                    open={deleteModalVisible}
                    onCancel={() => {
                        setDeleteModalVisible(false);
                        setSelectedEvent(null);
                    }}
                    footer={[
                        <button
                            key="Confirm"
                            onClick={() => {
                                handleDeleteEvent();
                                setSelectedEvent(null);
                            }}
                            className="primaryButton">
                            Confirm
                        </button>,
                        <button
                            key="Cancel"
                            onClick={() => {
                                setDeleteModalVisible(false);
                                setSelectedEvent(null);
                            }}
                            className="secondaryButton">
                            Cancel
                        </button>,
                    ]}>
                    Are you sure you want to delete the holiday?
                </Modal>
            </Row>
        </>
    );
};

export default ViewCalendar;
