import React, { useEffect } from "react";
import {
    Form,
    Input,
    DatePicker,
    InputNumber,
    Button,
    Row,
    Col,
    message,
    Alert,
    Modal,
    Radio,
} from "antd";
import { useState } from "react";
import styles from "../Awareness.module.css";
import { commonAxios } from "../../../utils/AxiosSettings";
import dayjs from "dayjs";
import UploadComponent from "../../../components/UploadComponent/UploadHeaderFile";
import { userState } from "../../../RecoilState/userState";
import { useRecoilState } from "recoil";
import { factoryState } from "../../../RecoilState/FactoryState";
import useEventMetricsUpdater from "../../../hooks/useEventMetricsUpdater";
import { debounce } from "lodash";

const convertToUTC = (inputDateString) => {
    const date = new Date(inputDateString);
    const year = date.getUTCFullYear();
    const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + date.getUTCDate()).slice(-2);
    const hours = ("0" + date.getUTCHours()).slice(-2);
    const minutes = ("0" + date.getUTCMinutes()).slice(-2);
    const seconds = ("0" + date.getUTCSeconds()).slice(-2);
    const milliseconds = ("00" + date.getUTCMilliseconds()).slice(-3);

    const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}Z`;

    return formattedDateString;
};
const AwarenessProgrammeUpload = ({ setUploadFalse }) => {
    const [form] = Form.useForm();
    const [showDelayField, setShowDelayField] = useState(false);
    const [user, setUser] = useRecoilState(userState);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [handleUpload, setHandleUpload] = useState(false);
    const [uploadFinished, setUploadFinished] = useState(false);
    const [awarenessDetails, setAwarenessDetails] = React.useState("");
    const [fileList, setFileList] = useState([]);
    const [FactorySelected, setFactorySelected] = useRecoilState(factoryState);
    const { updateEventMetrics } = useEventMetricsUpdater();
    const showConfirmationModal = () => {
        setIsModalVisible(true);
    };

    useEffect(() => {
        form.setFieldsValue({ file: fileList });
    }, [fileList]);

    const handleOk = async (eventName, actionType) => {
        try {
            const formattedDate = convertToUTC(form.getFieldValue("date"));
            const formData = new FormData();
            formData.append("programName", form.getFieldValue("programName"));
            formData.append(
                "programDuration",
                form.getFieldValue("duration").toString()
            );
            formData.append(
                "participants",
                form.getFieldValue("numParticipants").toString()
            );
            formData.append("Date", formattedDate);
            formData.append("Agenda", form.getFieldValue("agenda"));
            formData.append(
                "Description",
                form.getFieldValue("description")
                    ? form.getFieldValue("description")
                    : ""
            );
            showDelayField &&
                formData.append(
                    "Delay",
                    form.getFieldValue("delay")
                        ? form.getFieldValue("delay")
                        : ""
                );
            formData.append("Factory", FactorySelected.id);
            formData.append("Type", form.getFieldValue("type"));
            const response = await commonAxios.post(
                `${process.env.REACT_APP_BASE_URL_API}/api/accounts/awareness/`,
                formData
            );
            message.success(response.data.message);
            setAwarenessDetails(response.data.message_body);
            setHandleUpload(true);
            updateEventMetrics(eventName, actionType);
        } catch (error) {
            console.log(error);
            message.error("An error occurred while uploading the program.");
        }
        setIsModalVisible(false);
    };

    const onUploadComplete = () => {
        setUploadFinished(true);
        setUploadFalse();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleDateChange = async (date) => {
        try {
            const formattedDate = convertToUTC(date);
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL_API}/api/accounts/check/`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        Date: formattedDate,
                        Factory: FactorySelected?.id,
                    }),
                }
            );
            if (response.ok) {
                const data = await response.json();
                setShowDelayField(data["Delay Reason Required"]);
            } else {
                // Handle error case
                console.error("Failed to fetch API response");
            }
        } catch (error) {
            // Handle network or parsing error
            console.error("Error:", error);
        }
    };
    const debouncehandler = debounce(handleOk, 500);
    return (
        <div
            className={styles.mainContainer}
            style={{
                border: "1px solid #4318FF",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.25)",
            }}>
            {showDelayField && (
                <div
                    style={{
                        borderRadius: "5px",
                        position: "absolute",
                        top: -40,
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "30%",
                        zIndex: 9,
                    }}>
                    <Alert
                        closeIcon
                        type="warning"
                        message="Uploading a program of the previous month will not Impact Incentive cycle of any month."
                        showIcon
                        style={{ textAlign: "left", borderRadius: "15px" }}
                    />
                </div>
            )}
            <Row
                style={{
                    backgroundColor: "white",
                    margin: "0 2rem 2rem",
                    padding: "1rem",
                    borderRadius: "12px",
                    minHeight: "70vh",
                }}>
                <Col span={24}>
                    <Row
                        style={{
                            borderBottom: "1px solid #BEBEBE",
                            padding: "0 0 1rem 0",
                            marginTop: "1.5rem",
                            marginBottom: "1rem",
                        }}>
                        <Col flex={1} style={{ textAlign: "justify" }}>
                            <a onClick={setUploadFalse}>
                                <img src="/assets/images/back/Group4495.svg"></img>
                            </a>
                        </Col>
                        <Col
                            flex={20}
                            style={{
                                textAlign: "justify",
                                marginLeft: "1rem",
                            }}>
                            <h1>Upload Training Programme</h1>
                        </Col>
                    </Row>
                    <Row style={{ width: "100%" }}>
                        <Col style={{ width: "100%" }}>
                            <Form form={form} onFinish={showConfirmationModal}>
                                <Row gutter={16} style={{ width: "100%" }}>
                                    <Col style={{ width: "50%" }}>
                                        <Form.Item
                                            label="Type"
                                            name="type"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please Select Type",
                                                },
                                            ]}>
                                            <Radio.Group
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "start",
                                                    textAlign: "left",
                                                }}>
                                                <Row>
                                                    <Radio value="Inache Focused Program">
                                                        Inache Focused Program
                                                    </Radio>
                                                </Row>
                                                <Row>
                                                    <Radio value="Other Program mentioning Inache">
                                                        Other Program mentioning
                                                        Inache
                                                    </Radio>
                                                </Row>
                                                <Row>
                                                    <Radio value="Non-Inache Program">
                                                        Non-Inache Program
                                                    </Radio>
                                                </Row>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>

                                    <Col style={{ width: "50%" }}>
                                        <Form.Item
                                            label="Agenda"
                                            name="agenda"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please enter an agenda",
                                                },
                                            ]}>
                                            <Input.TextArea
                                                rows={4}
                                                placeholder="Define the agenda in 100 characters"
                                                maxLength={100}
                                                showCount
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "5px",
                                                    border: "1px solid #1B2559",
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16} style={{ width: "100%" }}>
                                    <Col style={{ width: "50%" }}>
                                        <Form.Item
                                            label="Programme Name"
                                            name="programName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please enter a program name",
                                                },
                                            ]}>
                                            <Input
                                                placeholder="Enter Programme Name"
                                                maxLength={30}
                                                showCount
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "5px",
                                                    border: "1px solid #1B2559",
                                                    height: "2.5rem",
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Date"
                                            name="date"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please select a date",
                                                },
                                            ]}>
                                            <DatePicker
                                                disabledDate={(current) =>
                                                    current &&
                                                    current.isAfter(
                                                        dayjs().endOf("day")
                                                    )
                                                }
                                                format="DD-MM-YYYY"
                                                placeholder="Select a Date"
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "5px",
                                                    border: "1px solid #1B2559",
                                                    height: "2.5rem",
                                                }}
                                                onChange={handleDateChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col style={{ width: "50%" }}>
                                        {showDelayField && (
                                            <Form.Item
                                                label="Reason for Delay"
                                                name="delay"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Please enter a delay",
                                                    },
                                                ]}>
                                                <Input
                                                    placeholder="Define the agenda in 100 characters"
                                                    maxLength={100}
                                                    showCount
                                                    style={{
                                                        width: "100%",
                                                        borderRadius: "5px",
                                                        border: "1px solid #1B2559",
                                                        height: "2.5rem",
                                                    }}
                                                />
                                            </Form.Item>
                                        )}
                                        <Form.Item
                                            label="Photos"
                                            name="file"
                                            rules={[
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        const allowedFileTypes =
                                                            [
                                                                "jpeg",
                                                                "jpg",
                                                                "png",
                                                            ];
                                                        const currentFileList =
                                                            getFieldValue(
                                                                "file"
                                                            );
                                                        // If there are no files, just return (the required rule will catch it)
                                                        if (
                                                            !currentFileList ||
                                                            currentFileList.length ===
                                                                0
                                                        ) {
                                                            return Promise.resolve();
                                                        }
                                                        for (let file of currentFileList) {
                                                            const fileType =
                                                                file.name
                                                                    .split(".")
                                                                    .slice(
                                                                        -1
                                                                    )[0];
                                                            if (
                                                                !allowedFileTypes.includes(
                                                                    fileType.toLowerCase()
                                                                )
                                                            ) {
                                                                return Promise.reject(
                                                                    new Error(
                                                                        "File type not allowed!"
                                                                    )
                                                                );
                                                            }
                                                        }

                                                        return Promise.resolve();
                                                    },
                                                }),
                                                {
                                                    required: true,
                                                    message:
                                                        "Please upload a file!",
                                                },
                                            ]}>
                                            <UploadComponent
                                                disableUpload={false}
                                                externalFileList={fileList}
                                                onExternalFileListChange={(
                                                    newFileList
                                                ) => setFileList(newFileList)}
                                                Files={[]}
                                                maxFiles="5"
                                                companyFk={user.company_fk}
                                                model="awareness_program"
                                                allowedFileTypes={[
                                                    "jpeg",
                                                    "jpg",
                                                    "png",
                                                ]}
                                                modelMethod="new"
                                                onModelCreated={handleUpload}
                                                setOnModalCreated={
                                                    setHandleUpload
                                                }
                                                id={awarenessDetails?.id}
                                                onUploadComplete={
                                                    onUploadComplete
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16} style={{ width: "100%" }}>
                                    <Col style={{ width: "50%" }}>
                                        <Form.Item
                                            label="Duration (in mins)"
                                            name="duration"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please enter a duration",
                                                },
                                                {
                                                    pattern: /^[0-9]+$/,
                                                    message:
                                                        "Please enter a valid number",
                                                },
                                                {
                                                    type: "number",
                                                    max: 120,
                                                    message:
                                                        "Please enter a number below 121",
                                                },
                                                {
                                                    type: "number",
                                                    min: 1,
                                                    message:
                                                        "Please enter a positive number",
                                                },
                                            ]}>
                                            <InputNumber
                                                maxLength={3}
                                                placeholder="00"
                                                pattern="[0-9]*"
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "5px",
                                                    border: "1px solid #1B2559",
                                                    height: "2.5rem",
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="No. of participants"
                                            name="numParticipants"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please enter the number of participants",
                                                },
                                                {
                                                    type: "number",
                                                    min: 1,
                                                    message:
                                                        "Please enter a positive number",
                                                },
                                                {
                                                    pattern: /^[0-9]+$/,
                                                    message:
                                                        "Please enter a valid number",
                                                },
                                                {
                                                    type: "number",
                                                    max: 99,
                                                    message:
                                                        "Please enter a number below 100",
                                                },
                                            ]}>
                                            <InputNumber
                                                placeholder="00"
                                                maxLength={2}
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "5px",
                                                    border: "1px solid #1B2559",
                                                    height: "2.5rem",
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col style={{ width: "50%" }}>
                                        <Form.Item
                                            label="Description"
                                            name="description">
                                            <Input.TextArea
                                                rows={4}
                                                maxLength={400}
                                                showCount
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "5px",
                                                    border: "1px solid #1B2559",
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row
                                    gutter={16}
                                    style={{ width: "100%" }}></Row>
                                <Row justify="end" gutter={16}>
                                    <Col span={2}>
                                        <Form.Item style={{ margin: "20px 0" }}>
                                            <Button
                                                type="secondary"
                                                className="secondaryButton"
                                                htmlType="cancel"
                                                onClick={() => {
                                                    setUploadFalse();
                                                }}
                                                style={{
                                                    width: "100%",
                                                    height: 40,
                                                }}>
                                                Cancel
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Form.Item style={{ margin: "20px 0" }}>
                                            <Button
                                                type="primary"
                                                className="primaryButton"
                                                htmlType="submit"
                                                style={{
                                                    width: "100%",
                                                    height: 40,
                                                }}>
                                                Save
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                            <Modal
                                title="Confirmation"
                                open={isModalVisible}
                                onOk={() =>
                                    debouncehandler(
                                        "awarenessProgrammeSave",
                                        "Upload awareness programme- SAVE"
                                    )
                                }
                                onCancel={handleCancel}>
                                <p>Are you sure you want to save?</p>
                            </Modal>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default AwarenessProgrammeUpload;
