import { commonAxios } from "../utils/AxiosSettings";

export const fetchTranscriptionData = (audioUrl, caseId) => {
    return commonAxios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/transcription/?url=${audioUrl}&case_id=${caseId}`
    );
};

export const submitFeedback = (transcriptionId, rating, comments) => {
    return commonAxios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/transcription/`,
        {
            transcription_id: transcriptionId,
            rating: rating,
            comments: comments,
        }
    );
};
