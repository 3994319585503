import React, { Suspense, useRef } from "react";
import { Layout, Menu, Space, Avatar, Dropdown } from "antd";
import styles from "./Header.module.css";
import { useRecoilState } from "recoil";
import { userState } from "../../RecoilState/userState";
import { Link } from "react-router-dom";
import { factoryState } from "../../RecoilState/FactoryState";
import UserProfileMenu from "./UserProfileMenu";
import { useEffect } from "react";
import { UserImageState } from "../../RecoilState/UserImageState";
import { ProfileModalState } from "../../RecoilState/ProfileModalState";
import { convertroleToText } from "../../utils/convertroleToText";
import { useLocation } from "react-router-dom";
import { getAllFactoriesOfCompany } from "../../Adapters/FactoryAndCompanyCalls";
import { tokenState } from "../../RecoilState/tokenState";
import { FactoryData } from "../../RecoilState/FactoryData";
import { currentSelectedRoleState } from "../../RecoilState/GlobalUserRoleStates/currentSelectedRoleState";
import RolesDropdown from "./RoleFactoryDropdown";
import DocumentViewer from "../UploadComponent/ShowDocHeaderFile";
import { useIdleTimer } from "react-idle-timer";
import useLogout from "../../hooks/useLogout";
import { Cookies } from "react-cookie";
import { companyDetailsState } from "../../RecoilState/CompanyDetails.js";
import { getCompanyDetails } from "../../Adapters/companyCalls.js";

const { Header: AntHeader } = Layout;

export default function Header() {
    const [user, setUser] = useRecoilState(userState);
    const [companyDetails, setCompanyDetails] =
        useRecoilState(companyDetailsState);
    const [showDropper, setshowDropper] = React.useState(false);
    const [FactoryDataList, setFactoryDataList] = useRecoilState(FactoryData);
    const [FactorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [UserImage, setUserImage] = useRecoilState(UserImageState);
    const [showProfile, setshowProfile] = useRecoilState(ProfileModalState);
    const [token, setToken] = useRecoilState(tokenState);
    const [currentSelectedRole, setCurrentSelectedRole] = useRecoilState(
        currentSelectedRoleState
    );
    const location = useLocation();
    const cookies = new Cookies();
    const { handleLogout } = useLogout();

    const handleOnIdle = async (event) => {
        if (localStorage.getItem("flag") === "true") {
            localStorage.setItem("flag", false);
        }
        await handleLogout("Idle Logout");
    };

    useIdleTimer({
        timeout: 1000 * 60 * 15,
        onIdle: handleOnIdle,
        debounce: 500,
        disabled: !cookies.get("token"),
    });

    function QCFunctionChecker() {
        let factoryCondition;
        if (!FactorySelected) {
            factoryCondition = user?.QCFactory == user.factory_fk;
        }
        if (FactorySelected) {
            factoryCondition = user?.QCFactory == FactorySelected.id;
        }
        return (
            factoryCondition &&
            user?.QCMonth == new Date().getMonth() + 1 &&
            user?.QCYear == new Date().getFullYear()
        );
    }

    const handleHover = () => {
        setshowDropper(true);
    };

    const handleLeave = () => {
        setshowDropper(false);
    };

    let userRole = convertroleToText(user.role);

    useEffect(() => {
        if (localStorage.getItem("current-selected-role")) {
            setCurrentSelectedRole(
                JSON.parse(localStorage.getItem("current-selected-role"))
            );
        }
    }, []);

    useEffect(() => {
        if (currentSelectedRole) {
            localStorage.setItem(
                "current-selected-role",
                JSON.stringify(currentSelectedRole)
            );
        }
    }, [currentSelectedRole]);

    useEffect(() => {
        if (
            location.pathname !== "/login" &&
            location.pathname !== "/" &&
            token &&
            user.company_fk !== undefined
        ) {
            if (FactoryDataList && user.company_fk !== undefined) {
                getAllFactoriesOfCompany(
                    user.company_fk,
                    token.access,
                    user.role === "REGIONAL_ADMIN" && "region"
                ).then((res) => {
                    const updatedFactoryList = res?.data?.message_body
                        ?.Factories
                        ? res.data.message_body.Factories.map((item) => ({
                              ...item,
                              key: item.id,
                          }))
                        : [];
                    setFactoryDataList(updatedFactoryList);

                    if (
                        [
                            "SUPER_ADMIN",
                            "REGIONAL_ADMIN",
                            "LEAD_SUPERVISOR",
                            "FACTORY_ADMIN",
                        ].includes(user?.role)
                    ) {
                        const filteredFactories =
                            res?.data?.message_body?.Factories.filter(
                                (f) => f.id == user.factory_fk
                            );
                        setFactorySelected(
                            filteredFactories?.length > 0
                                ? filteredFactories[0]
                                : res?.data?.message_body?.Factories[0]
                        );
                    } else {
                        setFactorySelected(
                            res.data.message_body.Factories.filter(
                                (f) => f.id == user.factory_fk
                            )[0]
                        );
                    }
                });
            }
        }
    }, [user.role, user.company_fk, token.access, currentSelectedRole]);

    useEffect(() => {
        if (user?.company_fk) {
            getCompanyDetails(user.company_fk).then((res) => {
                setCompanyDetails(res.data);
            });
        }
    }, [user?.company_fk]);

    let roleFinal;
    if (FactoryDataList) {
        if (QCFunctionChecker()) {
            roleFinal = "Quality Checker";
        } else if (user.role === "SUPER_ADMIN") {
            roleFinal = "Super Admin";
        } else if (
            user.factory_fk !==
            (FactorySelected ? FactorySelected?.id : FactoryDataList[0]?.id)
        ) {
            roleFinal = "You have no role in this Factory!";
        } else {
            roleFinal = userRole;
        }
    }

    if (user !== "" && location.pathname !== "/") {
        return (
            <AntHeader className={styles.header}>
                <div className={styles.leftSection}>
                    <RolesDropdown />
                </div>

                <div className={styles.centerSection}>
                    <Link to="/home">
                        <img
                            src="/assets/images/Logos/InacheLogoIcon.png"
                            height={55}
                            alt="Inache Logo"
                        />
                    </Link>
                </div>

                <div className={styles.rightSection}>
                    <Space
                        className={styles.userProfile}
                        onMouseEnter={handleHover}
                        onMouseLeave={handleLeave}>
                        <div
                            className={styles.userInfo}
                            onClick={() => setshowProfile(!showProfile)}>
                            <DocumentViewer
                                document_delete_on={false}
                                document_download_on={false}
                                documentIds={user.profile_picture}
                                model="profile_picture"
                                model_id={user.id}
                                previewHeight="40px"
                                previewWidth="40px"
                                previewType="circular"
                            />
                            <span>{user.user_name || user.email}</span>
                        </div>
                        <div
                            className={styles.expandMore}
                            onClick={() => setshowDropper(!showDropper)}>
                            <img
                                src="/assets/images/icon/navigation/expand_more_24px.svg"
                                height={10}
                                width={20}
                                alt="expand"
                            />
                            {true && (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <UserProfileMenu
                                        openProfile={showProfile}
                                        setOpenProfile={setshowProfile}
                                        userData={user}
                                        showDropper={showDropper}
                                        setshowDropper={setshowDropper}
                                    />
                                </Suspense>
                            )}
                        </div>
                    </Space>
                </div>
            </AntHeader>
        );
    }

    return (
        <AntHeader className={styles.loginHeader}>
            <div className={styles.loginLogo}>
                <img
                    src="/assets/images/Logos/InacheLogoIcon.png"
                    height={55}
                    alt="Inache Logo"
                />
            </div>
        </AntHeader>
    );
}
