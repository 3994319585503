import { useRecoilState } from "recoil";
import useFetchRegion from "../../../components/AdminDashboards/RegionDashboard/RegionHooks/FetchRegionHook";
import { useFetchFactories } from "../../../components/AdminDashboards/UserDashboard/UserAction/UserHooks/useFetchFactories";
import DynamicTable from "../../../components/Utils/DynamicTable/DynamicTable";
import { FactoryData } from "../../../RecoilState/FactoryData";
import { userState } from "../../../RecoilState/userState";
import { regionState } from "../../../RecoilState/regionState";
import React, { useEffect, useState } from "react";
import { Button, Input, Table, Tabs } from "antd";
import _ from "lodash";
import DocumentViewer from "../../../components/UploadComponent/ShowDocHeaderFile";
import DateTimeFormatter from "../../../components/Utils/DateTimeFormatter";
import { getAllPolicies } from "../../../Adapters/PolicyDashboard";
import ContainerLayout from "../../../containers/ContainerLayout/ContainerLayout";
import dayjs from "dayjs";

const TablePolicy = ({
    onRowClick,
    isEditMode,

    callGetPolicy,
}) => {
    const factoryData = useRecoilState(FactoryData);
    const [user, setUser] = useRecoilState(userState);
    const { fetchRegionData } = useFetchRegion();
    const { fetchFactoryData } = useFetchFactories();
    const [regionData] = useRecoilState(regionState);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [policyData, setPolicyData] = useState([]);
    const [activeTab, setActiveTab] = useState("1");

    useEffect(() => {
        const callgetPolicies = async () => {
            try {
                const response = await getAllPolicies(
                    "policy",
                    "dashboard",
                    activeTab === "1" ? "active" : "expired"
                );
                setPolicyData(response.data.message_body.Policies);
                if (user?.role === "SUPER_ADMIN") {
                    fetchRegionData();
                }
                fetchFactoryData();
            } catch (error) {
                console.error("Failed to fetch policies:", error);
            }
        };

        callgetPolicies();
    }, [activeTab, callGetPolicy, isEditMode]);
    const getRegionNames = (regionIds) => {
        return regionIds
            .map((id) => regionData.find((region) => region.id === id)?.Name)
            .filter(Boolean)
            .join(", ");
    };

    const getFactoryCodes = (factoryIds) => {
        const unwrappedFactoryData = Array.isArray(factoryData)
            ? factoryData[0]
            : factoryData;
        if (unwrappedFactoryData) {
            return factoryIds
                .map(
                    (id) =>
                        unwrappedFactoryData?.find(
                            (factory) => factory.id === id
                        )?.Code
                )
                .filter(Boolean)
                .join(", ");
        }
    };
    const filterData = (data, formatter) =>
        _.uniqBy(data, formatter).map((item, index) => ({
            text: formatter(item),
            value: formatter(item),
            key: `${formatter(item)}_${index}`,
        }));

    const searchDropdownVisible = (visible) => {
        if (visible) {
            setSelectedKeys([]);
        } else {
            setSelectedKeys([]);
        }
    };

    const searchDropdown = ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }) => {
        return (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder="Search"
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={confirm}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Button
                    type="primary"
                    onClick={confirm}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    Filter
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        confirm();
                    }}
                    size="small"
                    style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        );
    };
    // useEffect(() => {

    // },[activeTab])
    const columns = [
        {
            title: "Doc Preview",
            dataIndex: "attachment",
            key: "attachment",
            width: 220,
            render: (attachments, record) => (
                <div onClick={(e) => e.stopPropagation()}>
                    {attachments && attachments.length > 0 ? (
                        <DocumentViewer
                            document_delete_on={false}
                            document_download_on={false}
                            documentIds={attachments}
                            model={"policy"}
                            previewWidth="196px"
                            previewHeight="105px"
                            previewType="thumbnail"
                        />
                    ) : record.attached_link ? (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "196px",
                                height: "105px",
                                backgroundColor: "white",
                                border: "1px solid #d9d9d9",
                                borderRadius: "4px",
                                overflow: "hidden",
                                textAlign: "center",
                            }}>
                            <a
                                href={
                                    record.attached_link.startsWith("http")
                                        ? record.attached_link
                                        : `http://${record.attached_link}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: "#1890ff",
                                    fontWeight: "bold",
                                    textDecoration: "none",
                                    wordBreak: "break-all",
                                    padding: "0 10px",
                                }}>
                                {record.policy_name}
                            </a>
                        </div>
                    ) : (
                        "No Attachments"
                    )}
                </div>
            ),
        },
        {
            title: "Name",
            dataIndex: "policy_name",
            key: "name",
            width: 150,
            sorter: (a, b) => a.policy_name.localeCompare(b.policy_name),
            filterSearch: true,
            filters: filterData(policyData, (item) => item.policy_name),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const name = record.policy_name.toLowerCase().trim();
                return name.includes(searchValue);
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
        },
        {
            title: "Upload Details",
            key: "uploadDetails",
            width: 150,
            sorter: (a, b) => new Date(a.uploaded_at) - new Date(b.uploaded_at),
            render: (text, record) => (
                <>
                    {record.uploaded_by_role} <br />
                    <DateTimeFormatter
                        dateTimeString={record.uploaded_at}
                        requiredField="date"
                    />
                </>
            ),
            filterSearch: true,
            filters: filterData(
                policyData,
                (item) => `${item.uploaded_at} by ${item.uploaded_by_role}`
            ),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const uploadDetails =
                    `${record.uploaded_at} by ${record.uploaded_by_role}`
                        .toLowerCase()
                        .trim();
                return uploadDetails.includes(searchValue);
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
        },
        ...(user?.role == "SUPER_ADMIN"
            ? [
                  {
                      title: "Regions",
                      dataIndex: "regions",
                      width: 150,
                      key: "regions",
                      sorter: (a, b) =>
                          getRegionNames(a.regions).localeCompare(
                              getRegionNames(b.regions)
                          ),
                      render: (regions) => getRegionNames(regions),
                      filterSearch: true,
                      filters: filterData(policyData, (item) =>
                          getRegionNames(item.regions)
                      ),
                      onFilter: (value, record) => {
                          const searchValue = value.toLowerCase().trim();
                          const regions = getRegionNames(record.regions)
                              .toLowerCase()
                              .trim();
                          return regions.includes(searchValue);
                      },
                      filterDropdown: searchDropdown,
                      onFilterDropdownVisibleChange: searchDropdownVisible,
                  },
              ]
            : []),
        {
            title: "Factories",
            dataIndex: "factories",
            key: "factories",
            width: 150,
            sorter: (a, b) =>
                getFactoryCodes(a.factories).localeCompare(
                    getFactoryCodes(b.factories)
                ),
            render: (factories) => getFactoryCodes(factories),
            filterSearch: true,
            filters: filterData(policyData, (item) =>
                getFactoryCodes(item.factories)
            ),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const factories = getFactoryCodes(record.factories)
                    .toLowerCase()
                    .trim();
                return factories.includes(searchValue);
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
        },
        {
            title: "Users",
            dataIndex: "user_roles",
            key: "users",
            width: 150,
            sorter: (a, b) =>
                a.user_roles.join(", ").localeCompare(b.user_roles.join(", ")),
            render: (users) => users.join(", "),
            filterSearch: true,
            filters: filterData(policyData, (item) =>
                item.user_roles.join(", ")
            ),
            onFilter: (value, record) => {
                const searchValue = value.toLowerCase().trim();
                const users = record.user_roles.join(", ").toLowerCase().trim();
                return users.includes(searchValue);
            },
            filterDropdown: searchDropdown,
            onFilterDropdownVisibleChange: searchDropdownVisible,
        },
        {
            title: "Expiry Date",
            dataIndex: "expiration_date",
            key: "users",
            width: 150,
            sorter: (a, b) => {
                const dateA = dayjs(a.expiration_date);
                const dateB = dayjs(b.expiration_date);
                return dateA.isValid() && dateB.isValid()
                    ? dateA.unix() - dateB.unix()
                    : 0;
            },
            render: (date) => {
                const formattedDate = dayjs(date).isValid()
                    ? dayjs(date).format("DD-MM-YYYY")
                    : "";
                return formattedDate;
            },
        },
    ];
    const handleTabChange = (key) => {
        setActiveTab(key);
        setPolicyData([]);
    };

    // array with tab names, this will mapped to return each tab with its own table
    const tabNames = ["Active", "Expired"];
    const items = tabNames.map((tabName, index) => {
        return {
            key: (index + 1).toString(),
            label: tabName,
            children: (
                <div style={{ height: "100%" }}>
                    <DynamicTable
                        onRowClick={onRowClick}
                        data={policyData}
                        columns={columns}
                        enableOnRowClick={true}
                    />
                </div>
            ),
        };
    });
    return (
        <div style={{ height: "100%" }}>
            <ContainerLayout>
                <Tabs
                    defaultActiveKey="1"
                    onChange={handleTabChange}
                    items={items}></Tabs>
            </ContainerLayout>
        </div>
    );
};

export default TablePolicy;
