import { useEffect, useState } from "react";
import { getRoleToken } from "../../../Adapters/userCalls";
import { Cookies, useCookies } from "react-cookie";
import { useRecoilState } from "recoil";
import { commonAxios } from "../../../utils/AxiosSettings";
import { getAllFactoriesOfCompany } from "../../../Adapters/FactoryAndCompanyCalls";
import { userState } from "../../../RecoilState/userState";
import { FactoryData } from "../../../RecoilState/FactoryData";
import { currentSelectedRoleState } from "../../../RecoilState/GlobalUserRoleStates/currentSelectedRoleState";

const RoleToken = ({ role_id, tokens, setToken, loading, setter }) => {
    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(true);

    const [roleCookie, setRoleCookie, removeRoleCookie] = useCookies([
        "role-token-cookie",
    ]);
    const [userCookie, setUserCookie, removeUserCookie] = useCookies([
        "user-token-cookie",
    ]);
    const [user] = useRecoilState(userState);
    const [factoryList, setFactoryList] = useRecoilState(FactoryData);
    useEffect(
        () => {
            const fetchRoleToken = async () => {
                try {
                    if (
                        JSON.parse(
                            localStorage.getItem("current-selected-role")
                        ).id
                    ) {
                        const response = await getRoleToken(
                            JSON.parse(
                                localStorage.getItem("current-selected-role")
                            ).id,
                            tokens
                        ); // passing role_id from local storage
                        if (response.status === 200) {
                            cookies.set("role-token-cookie", response.data, {
                                path: "/",
                            });
                            cookies.set("token", response.data, {
                                path: "/",
                            });
                            const jwtPayload = JSON.parse(
                                window.atob(
                                    response.data?.refresh?.split(".")[1]
                                )
                            );

                            setToken({
                                ...response.data,
                                expires: jwtPayload.exp,
                            });
                            commonAxios.defaults.headers.common[
                                "Authorization"
                            ] = `Bearer ${response.data?.access}`;

                            setRoleCookie("role-token-cookie", response.data, {
                                path: "/",
                            });
                            removeUserCookie("user-token-cookie", {
                                path: "/",
                            });
                            if (user?.company_fk) {
                                getAllFactoriesOfCompany(
                                    user?.company_fk,
                                    response.data?.access,
                                    JSON.parse(
                                        localStorage.getItem(
                                            "current-selected-role"
                                        )
                                    ).role === "REGIONAL_ADMIN" && "region"
                                ).then((res) => {
                                    setFactoryList(
                                        res.data.message_body.Factories
                                    );
                                });
                            }
                        }
                    } else {
                        console.error("faillllll");
                    }
                } catch (error) {
                    if (error.response.status === 401) {
                        fetchRoleToken();
                    }
                    console.error("Error fetching new token:", error);
                } finally {
                    setIsLoading(false);
                }
            };

            if (isLoading && loading === "happening") {
                fetchRoleToken();
                setter("done");
            }
        },
        [localStorage.getItem("current-selected-role")],
        isLoading
    );

    return null;
};

export default RoleToken;
